import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    totalDataCount: 0
}

const totalDataCountSlice = createSlice({
  name: "totalDataCount",
  initialState,
  reducers: {
    setTotalDataCount: (state, action) => {
        state.totalDataCount = action.payload;
    }
  }
});

export const { setTotalDataCount } = totalDataCountSlice.actions;

export default totalDataCountSlice.reducer