import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { downloadReport } from "app/api/reportsDataApi";
import { ErrorAlert } from "app/components";
import { setLoading } from "app/redux/slices/interface/loaderSlice";
import { SimpleBackdrop } from "app/components/SimpleBackdrop/SimpleBackdrop";
import { setSearchQuery } from "app/redux/slices/data/searchQuerySlice";
import { setCurrentPage } from "app/redux/slices/data/tablesSlice";

const CustomizedSearchBar = ({ placeholder, onSearch }) => {
  const [query, setQuery] = useState("");
  const currentTable = useSelector((state) => state.tables.currentTable);
  const reportType = useSelector((state) => state.reportType.reportType);
  const searchQuery = useSelector((state) => state.searchQuery.searchQuery);
  const selectedColumns = useSelector((state) => state.columns.selectedColumns);
  const totalDataCount = useSelector((state) => state.totalDataCount.totalDataCount);
  const { startDay, endDay, startMonth, endMonth, startYear, endYear } = useSelector((state) => state.dateform);

  const dispatch = useDispatch();

  const handleDownload = async (e) => {
    if (currentTable === null) {
      ErrorAlert("No has generado un reporte aún. Por favor, genera uno primero.")
      return;
    } 
    if(totalDataCount === 0 || totalDataCount === null){
      onSearch("")
      ErrorAlert("No hay datos para descargar")
      return;
    } else {
      try {
        dispatch(setLoading(true));
        e.preventDefault();
        const response = await downloadReport(selectedColumns, searchQuery, startDay, endDay, startMonth, endMonth, startYear, endYear, reportType);
        const blob = response.file;
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = response.filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        dispatch(setLoading(false))
      } catch (error) {
        ErrorAlert("Error al descargar el reporte, intente más tarde")
      }
    }
  };

  const handleChange = (event) => {
    const query = event.target.value;
    setQuery(query);
  }

  const handleKeyDown = (event) => {
    const inputQuery = query ?? "";
    if(event.key === "Enter"){
      event.preventDefault();
      dispatch(setSearchQuery(inputQuery));
      dispatch(setCurrentPage(1));
    }
  }

  const handleSearch = (event) => {
    const inputQuery = query ?? "";
    dispatch(setSearchQuery(inputQuery));
    dispatch(setCurrentPage(1));
  }

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        border: ".1rem solid #a9a9a9",
        borderRadius: "2rem",
        margin: "2rem 0 3rem 0",
      }}
    >
      <Button onClick={handleSearch} sx={{ p: "1rem" }} aria-label="search">
        <SearchIcon sx={{ fontSize: "2rem" }} />
      </Button>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        onChange={handleChange}
        value={query}
        onKeyDown={handleKeyDown}
      />
      <Button onClick={handleDownload} sx={{ p: "1rem" }} aria-label="download">
        <DownloadIcon sx={{ fontSize: "2rem" }} />
      </Button>
      <SimpleBackdrop />
    </Paper>
  );
};

export { CustomizedSearchBar };
