import axiosInstance from "config/axiosConfig";

export const getUsers = async () => {
  try {
    const response = await axiosInstance.get("/api/v1/users/");
    return response.data.data.results;
  } catch (error) {
    console.error("Error al obtener los usuarios:", error.message);
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await axiosInstance.get(`/api/v1/users/me`);
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
}