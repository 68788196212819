import React, { useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { uploadFile } from "app/api/uploadFileApi";
import { ErrorAlert, SuccessAlert } from "app/components";
import { setLoading } from "app/redux/slices/interface/loaderSlice";
import { SimpleBackdrop } from "../SimpleBackdrop/SimpleBackdrop";
import "./DragAndDrop.scss";

const DragAndDrop = () => {
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [files, setFiles] = useState([]);
  const [firstUploadClicked, setFirstUploadClicked] = useState(false);
  const [reportType, setReportType] = useState("");
  const MAX_FILES = 1;
  const inputRef = useRef();
  const dispatch = useDispatch();

  const handleFileUpload = (uploadedFiles) => {
    if (uploadedFiles.length <= MAX_FILES) {
      setFiles(uploadedFiles);

      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.value = null;
      }
    } else ErrorAlert("Solo puedes cargar 1 archivo a la vez");
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files).map((file) => file);
    handleFileUpload(newFiles);
  };

  const handleFileUploaded = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.target.files).map((file) => file);
    handleFileUpload(newFiles);
  };

  const handleDelete = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      ErrorAlert("Selecciona un archivo para cargar");
      return;
    }

    if (!reportType) {
      ErrorAlert("Selecciona el tipo de reporte");
      return;
    }

    if (reportType === "facturation" || reportType === "update_base_data") {
      if (!year || !month) {
        ErrorAlert("Selecciona mes y año para continuar");
        return;
      }

      if (
        year &&
        (parseInt(year.length !== 4) ||
          parseInt(year) < 1990 ||
          !parseInt(year))
      ) {
        ErrorAlert("Ingresa un año válido");
        return;
      }
    }
    try {
      setFirstUploadClicked(true);
      dispatch(setLoading(true));
      const formData = new FormData();
      formData.append("report", files[0]);
      const response = await uploadFile(formData, reportType, year, month);
      if (response.status === 200) SuccessAlert("Archivo cargado exitosamente")
      if (response.status === 500) ErrorAlert("Ocurrió un error, corrobora tu conexión a internet y vuelve a intentar.");
      setFiles([]);
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
      setFirstUploadClicked(false);
    }
  };

  const handleChangeReportType = (event) => setReportType(event.target.value);
  const handleYearValue = (event) => setYear(event.target.value);
  const handleChangeMonth = (event) => setMonth(event.target.value);

  const months = useMemo(
    () => [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    []
  );

  return (
    <Box className="dnd-files">
      <SimpleBackdrop />
      <Box
        className="dnd-row-dropzone"
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleFileDrop}
      >
        <Box className="dnd-row-dropzone--container">
          <CloudUploadIcon />
          <p>Arrastra y suelta aquí el archivo</p>
          <p>o</p>
          <input
            type="file"
            onChange={handleFileUploaded}
            ref={inputRef}
            hidden
          />
          <Button variant="contained" onClick={() => inputRef.current.click()}>
            Buscar archivo
          </Button>
        </Box>
      </Box>

      <Box className="dnd-row-reportType">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Selecciona el tipo de reporte
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={reportType}
            label="Tipo de reporte"
            onChange={handleChangeReportType}
          >
            <MenuItem value="facturation">Financiera</MenuItem>
            {/* <MenuItem value="insurance">Seguros</MenuItem> */}
            {/* <MenuItem value="update_base_data">Seguro Distribuidores</MenuItem> */}
          </Select>
        </FormControl>
      </Box>

      {reportType !== "insurance" ? (
        <Box className="dnd-row-date">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Mes</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={month}
              label="Mes"
              onChange={handleChangeMonth}
            >
              {months.map((monthName, index) => (
                <MenuItem key={index} value={index + 1}>
                  {monthName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Año"
            variant="outlined"
            placeholder="Ej. 2024"
            value={year}
            onChange={handleYearValue}
          />
        </Box>
      ) : null}

      <div className="dnd-row-uploads">
        <span>Archivo seleccionado</span>
        <ul>
          {files &&
            files.map((file, index) => (
              <div className="dnd-row-uploads--item" key={index}>
                <div className="dnd-row-uploads--item__li">
                  <FilePresentIcon />
                  <li>{file.name}</li>
                  <Button
                    className="dnd-row-uploads--item__trash-icon"
                    onClick={() => handleDelete(index)}
                  >
                    <DeleteOutline />
                  </Button>
                </div>
              </div>
            ))}
        </ul>
      </div>
      <div className="dnd-row-actions">
        {files.length > 0 && (
          <>
            <Button onClick={() => setFiles([])}>Cancelar</Button>
            <Button onClick={handleUpload} disabled={firstUploadClicked}>
              Cargar
            </Button>
          </>
        )}
      </div>
    </Box>
  );
};

export { DragAndDrop };
