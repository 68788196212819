import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "app/redux/slices/auth/authSlice";
import uploadFilesReducer from "app/redux/slices/data/uploadFilesSlice";
import colorModeReducer from "app/redux/slices/interface/colorModeSlice";
import progressReducer from "app/redux/slices/interface/progressSlice";
import panelSettingsReducer from "app/redux/slices/interface/panelSettingsSlice";
import navbarContainerReducer from "app/redux/slices/interface/navbarContainerSlice";
import loaderReducer from "app/redux/slices/interface/loaderSlice";
import uploadLogsReducer from "app/redux/slices/data/uploadLogsSlice";
import usersReducer from "app/redux/slices/data/usersSlice";
import tablesReducer from "app/redux/slices/data/tablesSlice";
import columnsReducer from "app/redux/slices/data/columnsSlice";
import indicatorsReducer from "app/redux/slices/data/indicatorsSlice";
import totalDataCountReducer from "app/redux/slices/data/totalDataCountSlice";
import dateformReducer from "app/redux/slices/data/dateformSlice";
import searchQueryReducer from "app/redux/slices/data/searchQuerySlice";
import sortDataReducer from "app/redux/slices/data/sortDataSlice";
import reportTypeReducer from "app/redux/slices/data/reportTypeSlice";
import dashboardReducer from "app/redux/slices/data/dashboardSlice";

const authReducers = {
  auth: authReducer,
};

const dataReducers = {
  users: usersReducer,
  tables: tablesReducer,
  columns: columnsReducer,
  indicators: indicatorsReducer,
  totalDataCount: totalDataCountReducer,
  dateform: dateformReducer,
  searchQuery: searchQueryReducer,
  sortData: sortDataReducer,
  reportType: reportTypeReducer,
  dashboard: dashboardReducer,
};

const appReducers = {
  colorMode: colorModeReducer,
  uploadFiles: uploadFilesReducer,
  progress: progressReducer,
  panelSettings: panelSettingsReducer,
  navbarContainer: navbarContainerReducer,
  loader: loaderReducer,
  uploadLogs: uploadLogsReducer,
};

const RootReducer = combineReducers({
  ...authReducers,
  ...dataReducers,
  ...appReducers,
});

export const store = configureStore({
  reducer: RootReducer,
});
