// getReportsData.js
import { ErrorAlert } from "app/components";
import { formatMonth, getFilenameFromHeaders } from "app/utils/constants";
import axiosInstance from "config/axiosConfig";

const formatErrorMessages = (messages) => {
  let errorArrMsg = [];
  for (let message of messages) {
    for (let key in message) {
      if (message.hasOwnProperty(key)) {
        const formattedKey = key.replace(/_/g, " ");
        const formattedMessages = message[key].map((msg) =>
          msg.replace(/["{}[\]]/g, "")
        );
        errorArrMsg.push(`${formattedKey}: ${formattedMessages.join(", ")}`);
      }
    }
  }
  return errorArrMsg.join(". ");
};

export const createReport = async (
  selectedColumns,
  page,
  search,
  startDay,
  endDay,
  startMonth,
  endMonth,
  startYear,
  endYear,
  reportType
) => {
  try {
    const startPrincipalDate = `${formatMonth(startDay)}${formatMonth(
      startMonth
    )}${startYear}`;
    const finalPrincipalDate = `${formatMonth(endDay)}${formatMonth(
      endMonth
    )}${endYear}`;
    const requestBody = { columns: selectedColumns };

    const response = await axiosInstance.post(
      `/api/v1/reports/data/?type=${reportType}&start_principal_date=${startPrincipalDate}&final_principal_date=${finalPrincipalDate}&size=30&page=${page}&search=${search}`,
      requestBody
    );
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.messages) {
      const errorMessage = formatErrorMessages(error.response.data.messages);
      ErrorAlert(
        errorMessage.includes("fecha inválida")
          ? "Por favor, selecciona una fecha válida"
          : errorMessage
      );
    } else {
      ErrorAlert(
        "Error al crear el reporte, por favor, verifica tu conexión a internet o informa este problema al administrador."
      );
    }
  }
};

export const downloadReport = async (
  selectedColumns,
  search,
  startDay,
  endDay,
  startMonth,
  endMonth,
  startYear,
  endYear,
  reportType
) => {
  try {
    const startPrincipalDate = `${formatMonth(startDay)}${formatMonth(
      startMonth
    )}${startYear}`;
    const finalPrincipalDate = `${formatMonth(endDay)}${formatMonth(
      endMonth
    )}${endYear}`;
    const requestBody = { columns: selectedColumns };
    if (search === undefined || search === null) search = "";
    const response = await axiosInstance.post(
      `/api/v1/reports/download-file/?type=${reportType}&start_principal_date=${startPrincipalDate}&final_principal_date=${finalPrincipalDate}&search=${search}`,
      requestBody,
      { responseType: "blob", withCredentials: true }
    );
    const filename = getFilenameFromHeaders(response.headers);
    return { file: response.data, filename };
  } catch (error) {
    if (error.response && error.response.data && error.response.data.messages) {
      const errorMessage = formatErrorMessages(error.response.data.messages);
      ErrorAlert(
        errorMessage.includes("fecha inválida")
          ? "Por favor, selecciona una fecha válida"
          : errorMessage
      );
    } else {
      ErrorAlert(
        "Error al crear el reporte, por favor, verifica tu conexión a internet o informa este problema al administrador."
      );
    }
  }
};

export const downloadUpdateReportFormat = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/reports/file-structure/?type=insurance`,
      { responseType: "blob", withCredentials: true }
    );
    const filename = getFilenameFromHeaders(response.headers);
    return { file: response.data, filename };
  } catch (error) {
    ErrorAlert(
      "Error al descargar el formato, por favor, verifica tu conexión a internet o informa este problema al administrador."
    );
  }
};

export const getInsuranceReports = async (
  query,
  startDay,
  endDay,
  startMonth,
  endMonth,
  startYear,
  endYear,
  pageUrl = null // Nuevo parámetro opcional para navegación paginada
) => {
  try {
    const startPrincipalDate = `${formatMonth(startDay)}${formatMonth(startMonth)}${startYear}`;
    const finalPrincipalDate = `${formatMonth(endDay)}${formatMonth(endMonth)}${endYear}`;
    
    const url = pageUrl || // Usa `pageUrl` si está disponible
      (query
        ? `/api/v1/insurance/?search=${query}&start_siniestro_date=${startPrincipalDate}&final_siniestro_date=${finalPrincipalDate}&start_fecha_factura=${startPrincipalDate}&final_fecha_factura=${finalPrincipalDate}`
        : `/api/v1/insurance/?start_siniestro_date=${startPrincipalDate}&final_siniestro_date=${finalPrincipalDate}&start_fecha_factura=${startPrincipalDate}&final_fecha_factura=${finalPrincipalDate}`);

    const response = await axiosInstance.get(url);
    return response.data.data;
  } catch (error) {
    ErrorAlert(
      "Error al buscar los reportes, por favor, verifica tu conexión a internet o informa este problema al administrador."
    );
  }
};


export const postInsuranceReports = async (data) => {
  try {
    const response = await axiosInstance.post(`/api/v1/insurance/`, data);
    return response;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;
      let errorMessage = "";

      switch (status) {
        case 400:
          if (error.response.data && error.response.data.messages) {
            errorMessage = formatErrorMessages(error.response.data.messages);
          } else {
            errorMessage =
              "¡Oops! Parece que hubo un problema. Por favor, corrobora que los campos no estén duplicados.";
          }
          break;
        case 401:
          errorMessage =
            "No autorizado. Por favor, inicia sesión e intenta cargar el archivo nuevamente.";
          break;
        case 404:
          errorMessage = "Parece que hubo un problema.";
          break;
        case 500:
          errorMessage =
            "Por favor, corrobora tu conexión a internet y vuelve a intentar.";
          break;
        default:
          errorMessage =
            "Parece que hubo un problema. Por favor, corrobora los campos nuevamente.";
          break;
      }
      ErrorAlert(errorMessage);
    } else {
      ErrorAlert(
        "Parece que hubo un problema. Por favor, corrobora los campos nuevamente."
      );
    }
  }
};

export const updateInsuranceReport = async (slug, data) => {
  try {
    const response = await axiosInstance.put(
      `/api/v1/insurance/${slug}/`,
      data
    );
    return response;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;
      let errorMessage = "";

      switch (status) {
        case 400:
          if (error.response.data && error.response.data.messages) {
            errorMessage = formatErrorMessages(error.response.data.messages);
          } else {
            errorMessage =
              "¡Oops! Parece que hubo un problema. Por favor, corrobora que los campos no estén duplicados.";
          }
          break;
        case 401:
          errorMessage =
            "No autorizado. Por favor, inicia sesión e intenta cargar el archivo nuevamente.";
          break;
        case 404:
          errorMessage =
            "Archivo no encontrado. Por favor, verifica la ruta del archivo y vuelve a intentarlo.";
          break;
        case 500:
          errorMessage = "Reporte con formato incorrecto, valida nuevamente.";
          break;
        default:
          errorMessage =
            "Parece que hubo un problema. Por favor, corrobora los campos nuevamente.";
          break;
      }
      ErrorAlert(errorMessage);
    } else {
      ErrorAlert(
        "Parece que hubo un problema. Por favor, corrobora los campos nuevamente."
      );
    }
  }
};

export const deleteInsuranceReport = async (slug) => {
  try {
    const response = await axiosInstance.delete(`/api/v1/insurance/${slug}/`);
    return response;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;
      let errorMessage = "";

      switch (status) {
        case 400:
          if (error.response.data && error.response.data.messages) {
            errorMessage = formatErrorMessages(error.response.data.messages);
          } else {
            errorMessage =
              "¡Oops! Parece que hubo un problema. Por favor, corrobora que los campos no estén duplicados.";
          }
          break;
        case 401:
          errorMessage =
            "No autorizado. Por favor, inicia sesión e intenta cargar el archivo nuevamente.";
          break;
        case 404:
          errorMessage =
            "Archivo no encontrado. Por favor, verifica la ruta del archivo y vuelve a intentarlo.";
          break;
        case 500:
          errorMessage = "Reporte con formato incorrecto, valida nuevamente.";
          break;
        default:
          errorMessage =
            "Parece que hubo un problema. Por favor, corrobora los campos nuevamente.";
          break;
      }
      ErrorAlert(errorMessage);
    } else {
      ErrorAlert(
        "Parece que hubo un problema. Por favor, corrobora los campos nuevamente."
      );
    }
  }
};

export const downloadInsuranceReport = async (
  query,
  startDay,
  endDay,
  startMonth,
  endMonth,
  startYear,
  endYear
) => {
  try {
    const startPrincipalDate = `${formatMonth(startDay)}${formatMonth(startMonth)}${startYear}`;
    const finalPrincipalDate = `${formatMonth(endDay)}${formatMonth(endMonth)}${endYear}`;

    const url = query
      ? `/api/v1/insurance/download-file/?search=${query}&start_siniestro_date=${startPrincipalDate}&final_siniestro_date=${finalPrincipalDate}&start_fecha_factura=${startPrincipalDate}&final_fecha_factura=${finalPrincipalDate}`
      : `/api/v1/insurance/download-file/?start_siniestro_date=${startPrincipalDate}&final_siniestro_date=${finalPrincipalDate}&start_fecha_factura=${startPrincipalDate}&final_fecha_factura=${finalPrincipalDate}`;

    const response = await axiosInstance.post(url, null, {
      responseType: "blob",
      withCredentials: true,
    });

    const filename = getFilenameFromHeaders(response.headers);
    return { file: response.data, filename };
  } catch (error) {
    if (error.response && error.response.data && error.response.data.messages) {
      const errorMessage = formatErrorMessages(error.response.data.messages);
      ErrorAlert(
        errorMessage.includes("fecha inválida")
          ? "Por favor, selecciona una fecha válida"
          : errorMessage
      );
    } else {
      ErrorAlert(
        "Error al crear el reporte, por favor, verifica tu conexión a internet o informa este problema al administrador."
      );
    }
  }
};

export const createVin = async (vinData) => {
  try {
    const response = await axiosInstance.post("/api/v1/insurance/vin/", vinData);
    console.log('>>>>>>>>>>> response', response);
    return response;
  } catch (error) {
    console.log('>>>>>>>>>>>', error);
    throw error; // Propaga el error para manejarlo fuera de la función
  }
}
