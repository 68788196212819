import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    mode: true
}

const colorModeSlice = createSlice({
  name: "colorMode",
  initialState,
  reducers: {
    setColorMode: (state, action) => {
        state.mode = action.payload;
    }
  }
});

export const { setColorMode } = colorModeSlice.actions;

export default colorModeSlice.reducer;