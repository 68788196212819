import React, { useEffect, useState } from "react";
import { TotalCards } from "app/components/TotalCards/TotalCards";
import { CalendarMonth } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Dateform, PageTitle } from "app/components";
import { getUnitsPerDist } from "app/services/dashboard";
import { BasicAutocomplete } from "app/components/BasicAutocomplete/BasicAutocomplete";
import { UnitsPerDistributorChart } from "app/components/Charts/UnitsPerDistributorChart";
import { useSelector } from "react-redux";
import { formatMonth } from "app/utils/constants";

const Dashboard = () => {
  const [distributorsList, setDistributorsList] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState({});
  const [distributorFees, setDistributorFees] = useState([]);
  const [unitsPerDist, setUnitsPerDist] = useState([]);
  const [openDateform, setOpenDateform] = useState(false);
  const { startDay, endDay, startMonth, endMonth, startYear, endYear } =
    useSelector((state) => state.dateform);

  const startPrincipalDate = `${formatMonth(startDay)}${formatMonth(
    startMonth
  )}${startYear}`;
  const finalPrincipalDate = `${formatMonth(endDay)}${formatMonth(
    endMonth
  )}${endYear}`;

  const handleOpenDateform = () => {
    setOpenDateform(true);
  };

  const handleCloseDateform = () => {
    setOpenDateform(false);
  };

  const defaultOption = {
    concesionario_id: "1",
    concesionario: "Ninguna opción seleccionada",
  };

  useEffect(() => {
    fetchUnitsPerDistributor();
  }, [startPrincipalDate, finalPrincipalDate]); // Agregar fechas a las dependencias

  const fetchUnitsPerDistributor = async () => {
    try {
      // Aquí pasamos las fechas a la función de obtención
      const response = await getUnitsPerDist(startPrincipalDate, finalPrincipalDate);
      setUnitsPerDist(response);

      const distList = response.map((item) => ({
        concesionario_id: item.concesionario_id,
        concesionario: item.concesionario,
      }));
      setDistributorsList(distList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDistributorSelection = (value) => {
    setSelectedDistributor(value);
  };

  return (
    <Box className="py-[30px] px-[30px] xl:px-[70px] xl:py-[30px] w-full h-full min-h-screen ">
      <Box className="mb-3">
        <PageTitle title="Dashboard" text="Insights" />
      </Box>
      <Box className="flex flex-col justify-center items-start gap-7">
        <Button
          className="text-sm flex items-center gap-2"
          onClick={handleOpenDateform}
        >
          <CalendarMonth sx={{ fontSize: "2.5rem" }} /> &nbsp;{" "}
          <p className="text-xl">
            Selecciona un rango de fechas de visualización
          </p>
        </Button>
        <p className="text-xl">Información correspondiente al periodo: {startDay} / {startMonth} / {startYear} - {endDay} / {endMonth} / {endYear} </p>
        <Box className="mb-7">
          <p className="text-xl mb-4">Distribuidor seleccionado</p>
          <BasicAutocomplete
            acOptions={distributorsList}
            label="Selecciona un distribuidor"
            onChange={handleDistributorSelection}
            defaultOption={defaultOption}
          />
        </Box>
      </Box>
      <Box className="w-full flex flex-col gap-10">
        <Box className="w-full flex flex-wrap">
          <TotalCards distributor={selectedDistributor} />
        </Box>
        <Box className="flex items-center justify-center px-4 w-full xl:px-0 xl:justify-center">
          <Box className="w-full xl:w-[500px]">
            <UnitsPerDistributorChart
              data={unitsPerDist}
              distributor={selectedDistributor}
            />
          </Box>
        </Box>
      </Box>
      <Dateform open={openDateform} handleClose={handleCloseDateform} />
    </Box>
  );
};

export { Dashboard };
