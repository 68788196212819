import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sortConfigs: {}
}

const sortDataSlice = createSlice({
  name: "sortData",
  initialState,
  reducers: {
    setSortConfigs: (state, action) => {
        state.sortConfigs = action.payload;
    }
  }
});

export const { setSortConfigs } = sortDataSlice.actions

export default sortDataSlice.reducer