import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    totalUdp: 0,
    totalCuotaAsociacion: 0,
    totalCuotaConvencion: 0,
    totalCuotaPublicidad: 0,
    totalCuotaCapacitacion: 0,
    totalCuotaAmda: 0,
}

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setTotalUdp: (state, action) => {
        state.totalUdp = action.payload;
    },
    setTotalCuotas: (state, action) => {
        state.totalCuotaAsociacion = action.payload.totalCuotaAsociacion;
        state.totalCuotaConvencion = action.payload.totalCuotaConvencion;
        state.totalCuotaPublicidad = action.payload.totalCuotaPublicidad;
        state.totalCuotaCapacitacion = action.payload.totalCuotaCapacitacion;
        state.totalCuotaAmda = action.payload.totalCuotaAmda;
    }
  }
});

export const { setTotalUdp, setTotalCuotas } = dashboardSlice.actions;

export default dashboardSlice.reducer;
