import React, { useEffect, useState } from "react";
import { Input } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axiosInstance from "config/axiosConfig";
import { WaterMark } from "app/components/shared/WaterMark/WaterMark";
import { MuiAlert } from "app/components/MuiAlert/MuiAlert";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "app/redux/slices/auth/authSlice";
import { ErrorAlert } from "app/components";
import logo from "app/assets/images/logo.png";
import "./LogInForm.scss";
import { fetchPermissions } from "app/api/permissionsApi";
import { setPermissions } from "app/redux/slices/data/usersSlice";

const steps = ["", ""];

const LogInForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const permissionsData = await fetchPermissions();
    dispatch(setPermissions(permissionsData));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleNext = () =>
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (activeStep === 0) {
        handleNext();
      } else if (activeStep === 1) {
        handleSubmit();
      }
    }
  };

  const handleLoginSuccess = () => {
    dispatch(setIsAuthenticated(true));
    navigate("/dashboard");
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);

    axiosInstance
      .post("/api/v1/users/login/", formData)
      .then((response) => {
        // const token = response.data.access_token;
        // localStorage.setItem("token", token);
        handleLoginSuccess();
      })
      .catch((error) => {
        ErrorAlert("Usuario o contraseña incorrectos")
      });
  };

  const handleClick = () => setAlertOpen(true);
  const handleClose = () => setAlertOpen(false);

  return (
    <Box className="login">
      <Box className="login-container">
        <Box className="login-box">
          <figure className="login-logo">
            <img src={logo} alt="Adeva logo" />
          </figure>
          <Box className="login-content">
            <Box className="login-label">
              <InputLabel htmlFor="input-with-icon-adornment">
                {activeStep === 0 ? "Usuario" : "Contraseña"}
              </InputLabel>
            </Box>
            <Box className="login-inputs">
              <Input
                required
                id="input-with-icon-adornment"
                type={
                  activeStep === 0 ? "text" : showPassword ? "text" : "password"
                }
                placeholder={
                  activeStep === 0
                    ? "Escribe aquí tu usuario"
                    : "Escribe aquí tu contraseña"
                }
                value={activeStep === 0 ? email : password}
                onChange={(e) => {
                  activeStep === 0
                    ? setEmail(e.target.value)
                    : setPassword(e.target.value);
                }}
                onKeyPress={handleKeyPress}
                startAdornment={
                  activeStep === 0 && (
                    <InputAdornment position="start">
                      <AccountCircle sx={{ fontSize: 20 }} />
                    </InputAdornment>
                  )
                }
                endAdornment={
                  activeStep === 1 && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <Visibility style={{ fontSize: 20, marginBottom: "1.5rem" }} />
                        ) : (
                          <VisibilityOff style={{ fontSize: 20, marginBottom: "1.5rem" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
              {activeStep === 1 && (
                <>
                  <Button onClick={handleClick}>
                    ¿Olvidaste tu contraseña?
                  </Button>
                  {alertOpen && (
                    <MuiAlert
                      variant="outlined"
                      severity="info"
                      onClose={handleClose}
                      text={`Solicita la recuperación por correo`}
                      text2={`a contabilidad@adeva.com.mx`}
                      action={() => setAlertOpen(false)}
                    />
                  )}
                </>
              )}
            </Box>
            <Box className="login-stepper-btns">
              {activeStep !== steps.length - 1 && (
                <Button onClick={handleNext}>Siguiente</Button>
              )}
              {activeStep !== 0 && (
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Regresar
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button onClick={handleSubmit}>Iniciar sesión</Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <WaterMark />
    </Box>
  );
};

export default LogInForm;