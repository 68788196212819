import { LinearProgress } from "@mui/material";
import logo from "app/assets/images/logo.png";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { getCurrentUser } from "app/api/usersApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "app/redux/slices/data/usersSlice";

const AppLoader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCurrentUser();
    // eslint-disable-next-line
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const response = await getCurrentUser();
      dispatch(setCurrentUser(response));
      const user = {
        first_name: response.first_name,
        last_name: response.last_name,
        permission_name: response.permission.name,
      };
      sessionStorage.setItem('currentUser', JSON.stringify(user));
    } catch (error) {
      console.error(error);
    } finally {
      navigate("/dashboard");
    }
  };
  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        width: "100%",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Box sx={{ marginTop: "-5rem" }}>
        <img src={logo} alt="Logo" style={{ width: "29rem" }} />
        <LinearProgress sx={{ marginTop: "2rem" }} />
      </Box>
    </Box>
  );
};

export { AppLoader };
