import { TextField } from "@mui/material";
import React from "react";

const TextItem = ({ text, label, name, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div className="text-item">
      <p>{text}</p>
      <TextField
        required
        label={label}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export { TextItem };
