import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Slide } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import TableChartIcon from "@mui/icons-material/TableChart";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { setIsMenuOpen } from "app/redux/slices/interface/navbarContainerSlice";
import { setIsAuthenticated } from "app/redux/slices/auth/authSlice";
import logo from "app/assets/images/white-logo.png";
import "./Navbar.scss";
import { Avatar } from "app/components";
import { shortName } from "app/utils/constants";

const Navbar = () => {
  const isMenuOpen = useSelector((state) => state.navbarContainer.isMenuOpen);
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const menuRef = useRef(null);
  // eslint-disable-next-line
  const [mouseDownOnMenu, setMouseDownOnMenu] = useState(false);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current || !menuRef.current.contains(event.target)) {
        if (isMenuOpen && !menuButtonRef.current.contains(event.target)) {
          dispatch(setIsMenuOpen(false));
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch, isMenuOpen]);

  const handleLogout = () => {
    dispatch(setIsAuthenticated(false));
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    navigate("/");
  };

  const handleLinkClick = () => {
    dispatch(setIsMenuOpen(false));
  };

  const toggleMenu = () => {
    dispatch(setIsMenuOpen(!isMenuOpen));
  };

  return (
    <nav className={`navbar`}>
      <Box className={`navbar-nav`}>
        <Box className="navbar-nav__menu">
          <Button ref={menuButtonRef} onClick={toggleMenu}>
            <MenuIcon />
          </Button>
          <figure className="navbar-nav__logo">
            <img src={logo} alt="Adeva logo" />
          </figure>
        </Box>
        <Box className="navbar-nav__options">
          <Button onClick={handleLogout}>Cerrar sesión</Button>
        </Box>
      </Box>
      <Slide direction="right" in={isMenuOpen} mountOnEnter unmountOnExit>
        <Box
          ref={menuRef}
          className={`navbar-container`}
          onMouseDown={() => setMouseDownOnMenu(true)}
          onMouseUp={() => setMouseDownOnMenu(false)}
        >
          <Box className="navbar-container__box">
            <Box className="navbar-container__user">
              <Avatar
                name={currentUser.first_name}
                lastname={currentUser.last_name}
              />
              <Box className="navbar-container__user-info">
                <p>{shortName(currentUser.first_name, currentUser.last_name)}</p>
                <span>{currentUser.permission_name}</span>
              </Box>
            </Box>
            <Box className="navbar-container__section">
              <p>Visualización de datos</p>
              <Box
                className={`navbar-container__section-item ${
                  location.pathname === "/dashboard" ? "active" : ""
                }`}
              >
                <DashboardCustomizeIcon />
                <Link to="/dashboard" onClick={handleLinkClick}>
                  Dashboard
                </Link>
              </Box>
            </Box>
            <Box className="navbar-container__section">
              <p>Reportes</p>
              <Box
                className={`navbar-container__section-item ${
                  location.pathname === "/reportes-contabilidad" ? "active" : ""
                }`}
              >
                <TableChartIcon />
                <Link to="/reportes-contabilidad" onClick={handleLinkClick}>
                  Contabilidad
                </Link>
              </Box>
              <Box
                className={`navbar-container__section-item ${
                  location.pathname === "/reportes-seguros" ? "active" : ""
                }`}
              >
                <TableChartIcon />
                <Link to="/reportes-seguros" onClick={handleLinkClick}>
                  Seguros
                </Link>
              </Box>
            </Box>
            <Box className="navbar-container__section">
              <p>Gestión</p>
              <Box
                className={`navbar-container__section-item ${
                  location.pathname === "/usuarios" ? "active" : ""
                }`}
              >
                <PeopleOutlineIcon />
                <Link to="/usuarios" onClick={handleLinkClick}>
                  Usuarios
                </Link>
              </Box>
              <Box
                className={`navbar-container__section-item ${
                  location.pathname === "/indicadores" ? "active" : ""
                }`}
              >
                <ModeEditIcon />
                <Link to="/indicadores" onClick={handleLinkClick}>
                  Indicadores
                </Link>
              </Box>
            </Box>
            <Box className="navbar-container__section">
              <p>General</p>
              <Box
                className={`navbar-container__section-item ${
                  location.pathname === "/archivos" ? "active" : ""
                }`}
              >
                <UploadFileIcon />
                <Link to="/archivos" onClick={handleLinkClick}>
                  Archivos
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
    </nav>
  );
};

export { Navbar };
