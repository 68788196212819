import axiosInstance from "config/axiosConfig";

export const getColumnNames = async () => {
    try {
        const response = await axiosInstance.get('/api/v1/reports/column-names');
        return response.data.data.results;
    } catch (error) {
        console.error('Error fetching column names', error)
        return []
    }
}