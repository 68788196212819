import React from "react";
import icon from "app/assets/images/excel_icon.png";
import { Box } from "@mui/material";
import "./FileCard.scss";
import { formatDate } from "app/utils/constants";

const FileCard = React.forwardRef(({ filename, created, onClick }, ref) => {
  const createdDate = formatDate(created);
  return (
    <Box className="filecard" ref={ref} onClick={onClick}>
      <figure className="filecard-image">
        <img src={icon} alt="Excel icon" />
      </figure>
      <Box className="filecard-desc">
        <h4 className="filecard-desc-title">{filename}</h4>
        <p className="filecard-desc-text">Última modificación: <br/> {createdDate}</p>
      </Box>
    </Box>
  );
});

export { FileCard };
