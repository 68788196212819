import React from "react";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { ArrowRightAlt } from "@mui/icons-material";
import "./PageTitle.scss";

const PageTitle = ({ title, text }) => {
  return (
    <div className="page-title">
      <ViewInArIcon className="title-icon" />
      <h2>{title}</h2>
      <span>
        <ArrowRightAlt />
      </span>
      <p>{text}</p>
    </div>
  );
};

export { PageTitle };
