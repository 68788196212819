import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
  deleteInsuranceReport,
  postInsuranceReports,
  updateInsuranceReport,
  createVin,
} from "app/api/reportsDataApi";
import { ErrorAlert, SuccessAlert } from "..";
import { useDispatch } from "react-redux";
import { setInsuranceData, setAllInsuranceData } from "app/redux/slices/data/tablesSlice";
import { getInsuranceReports } from "app/api/reportsDataApi";
import Autocomplete from "@mui/material/Autocomplete";

const FormDialog = (props) => {
  const { open, handleClose, titles, mode } = props;
  const [selectedTable, setSelectedTable] = React.useState("");
  const [formData, setFormData] = React.useState({});
  const [searchedData, setSearchedData] = React.useState(null);
  const [noResultsNumeroSiniestro, setNoResultsNumeroSiniestro] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const data = useSelector((state) => state.tables.allInsuranceData);
  const dispatch = useDispatch();

  const fetchInsuranceData = async () => {
    const defaultStartDay = 1;
    const defaultEndDay = 31;
    const defaultStartMonth = 1;
    const defaultEndMonth = 12;
    const defaultStartYear = 2000;
    const defaultEndYear = new Date().getFullYear();

    try {
      const response = await getInsuranceReports(null, defaultStartDay, defaultEndDay, defaultStartMonth, defaultEndMonth, defaultStartYear, defaultEndYear);
      dispatch(setInsuranceData(response));
      dispatch(setAllInsuranceData(response));
    } catch (error) {
      console.error('Error fetching insurance data:', error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedTable(event.target.value);
    setSearchedData(null);
    setNoResultsNumeroSiniestro(false);
    setFormData({});
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrorMessage("");
  };

  const handleSearchNumeroSiniestro = () => {
    setNoResultsNumeroSiniestro(false);
    const searchTerm = formData.numero_siniestro;

    if (!searchTerm) return;

    const foundData = data.results.find(
      (item) => item.numero_siniestro === searchTerm
    );

    if (foundData) {
      setSearchedData(foundData);
      setFormData({ ...formData, ...foundData });
      setNoResultsNumeroSiniestro(false);
    } else {
      setSearchedData(null);
      setNoResultsNumeroSiniestro(true);
    }
  };

  const handleSearchVIN = () => {
    setNoResultsNumeroSiniestro(false);
    const searchTerm = formData.vin;

    if (!searchTerm) return;

    const foundData = data.results.find(
      (item) => item.vin === searchTerm
    );

    if (foundData) {
      setSearchedData(foundData);
      setNoResultsNumeroSiniestro(false);
    } else {
      setSearchedData(null);
      setNoResultsNumeroSiniestro(true);
    }
  };

  const handleNumeroSiniestroChange = (event, newInputValue) => {
    const relatedSiniestros = data.results.filter(item => item.vin === formData.vin);
    setFormData({ ...formData, numero_siniestro: newInputValue });

    const selectedSiniestro = relatedSiniestros.find(item => item.numero_siniestro === newInputValue);
    if (selectedSiniestro) {
      setFormData({ ...formData, ...selectedSiniestro });
    }
  };

  const formatDateToYYYYMMDD = (date) => {
    const day = (`0${date.getDate()}`).slice(-2);
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const year = date.getFullYear();
    return `${year}${month}${day}`;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (mode === "delete" && !formData.numero_siniestro) {
      ErrorAlert("Por favor, ingrese un número de siniestro válido.");
      return;
    }

    try {
      switch (mode) {
        case "add":
          if (selectedTable === "Dar de alta una unidad") {
            if (!formData.vin || formData.vin.length !== 7) {
              ErrorAlert("Por favor, ingrese un VIN válido.");
              return;
            }
            const response = await createVin({ vin: formData.vin });
            if (response) {
              handleClose();
              SuccessAlert("VIN creado con éxito!");
              setFormData({});
              await fetchInsuranceData();
              return;
            }
          } else {
            const vinExists = data.results.some((item) => item.vin === formData.vin);

            if (selectedTable === "Movimientos de Siniestros" && !formData.fecha_siniestro) {
              formData.fecha_siniestro = formatDateToYYYYMMDD(new Date());
            }

            if (selectedTable === "Movimientos de Facturas" && !formData.fecha_factura) {
              formData.fecha_factura = formatDateToYYYYMMDD(new Date());
            }

            if (selectedTable === "Pago de Facturas" && !formData.fecha_pago_factura) {
              formData.fecha_pago_factura = formatDateToYYYYMMDD(new Date());
            }

            if (!vinExists) {
              await createVin({ vin: formData.vin });
            }
            const response = await postInsuranceReports(formData);
            if (response) {
              handleClose();
              SuccessAlert("Registro añadido con éxito!");
              setFormData({});
              await fetchInsuranceData();
              return;
            }
          }
          break;
        case "edit":
          if (formData.slug) {
            const response = await updateInsuranceReport(formData.slug, formData);
            if (response) {
              handleClose();
              SuccessAlert("Actualización exitosa!");
              setFormData({});
              await fetchInsuranceData();
              return;
            }
          }
          break;
        case "delete":
          if (!formData.numero_siniestro) {
            ErrorAlert("Por favor, ingrese un número de siniestro válido.");
            return;
          }

          const recordToDelete = data.results.find(
            (item) => item.numero_siniestro === formData.numero_siniestro
          );

          if (!recordToDelete) {
            ErrorAlert("Número de siniestro no encontrado.");
            return;
          }
          const response = await deleteInsuranceReport(recordToDelete.slug);
          if (response) {
            handleClose();
            SuccessAlert("Registro eliminado con éxito!");
            setFormData({});
            await fetchInsuranceData();
            return;
          }
          break;
        default:
          break;
      }
    } catch (error) {
      handleClose();
      ErrorAlert(error.message);
    }
  };

  const getFormFields = () => {
    if (!selectedTable || mode === "delete") return null;

    const fields = [];

    if (selectedTable === "Movimientos de Siniestros") {
      fields.push(
        "poliza_seguro",
        "fecha_siniestro",
        "reportado",
        "propietario",
        "concepto",
        "lugar",
        "chofer",
        "fecha_valuacion",
        "cia",
        "ajustador",
        "factura"
      );
    } else if (selectedTable === "Movimientos de Facturas") {
      fields.push(
        "poliza_seguro",
        "factura",
        "fecha_factura",
        "reparador",
        "ocurrido",
        "ga",
        "gai",
        "recupero",
        "salvamento",
        "rc",
        "total"
      );
    } else if (selectedTable === "Pago de Facturas") {
      fields.push(
        "poliza_seguro",
        "factura",
        "cheque",
        "fecha_pago_factura",
        "banco",
        "monto",
        "reportado_a",
        "fecha_reporte"
      );
    }

    return fields.map((field) => (
      <Box key={field} sx={{ margin: "2rem 0" }}>
        <InputLabel
          sx={{
            color: "#0267d3",
            fontSize: "1.3rem",
            fontWeight: 700,
            textTransform: "capitalize",
            marginBottom: "1rem",
          }}
          htmlFor={field}
        >
          {field.split("_").join(" ")}
        </InputLabel>
        <TextField
          margin="dense"
          id={field}
          name={field}
          placeholder={field.split("_").join(" ")}
          type="text"
          fullWidth
          variant="outlined"
          onChange={handleInputChange}
          value={formData[field] || ""}
        />
      </Box>
    ));
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleFormSubmit,
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "1.5rem",
            fontWeight: 700,
            textTransform: "capitalize",
          }}
        >
          {titles[mode]}
        </DialogTitle>
        <DialogContent>
          {mode !== "delete" && (
            <FormControl size="large">
              <h3 id="select-table-label">Selecciona una opción</h3>
              <Select
                labelId="select-table-label"
                id="select-table"
                value={selectedTable}
                label="Seleccionar tabla"
                onChange={handleSelectChange}
              >
                <MenuItem value={"Movimientos de Siniestros"}>
                  Movimientos de Siniestros
                </MenuItem>
                <MenuItem value={"Movimientos de Facturas"}>
                  Movimientos de Facturas
                </MenuItem>
                <MenuItem value={"Pago de Facturas"}>Pago de Facturas</MenuItem>
                {mode === "add" && (
                  <MenuItem value={"Dar de alta una unidad"}>Dar de alta una unidad</MenuItem>
                )}
              </Select>
            </FormControl>
          )}
          {mode === "delete" ? (
            <Box mt={2}>
              <InputLabel
                htmlFor="numero_siniestro"
                sx={{
                  color: "#0267d3",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  textTransform: "capitalize",
                  marginBottom: "1rem",
                }}
              >
                Número de siniestro
              </InputLabel>
              <TextField
                margin="dense"
                id="numero_siniestro"
                name="numero_siniestro"
                placeholder="Número de siniestro"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleInputChange}
                value={formData.numero_siniestro || ""}
              />
              {noResultsNumeroSiniestro && (
                <Box sx={{ color: "red", marginBottom: "1rem" }}>
                  No hay coincidencias
                </Box>
              )}
            </Box>
          ) : (
            selectedTable && (
              <Box mt={2}>
                <InputLabel
                  htmlFor="vin"
                  sx={{
                    color: "#0267d3",
                    fontSize: "1.3rem",
                    fontWeight: 700,
                    textTransform: "capitalize",
                    marginBottom: "1rem",
                  }}
                >
                  VIN
                </InputLabel>
                <Box display="flex" alignItems="center">
                  <TextField
                    margin="dense"
                    id="vin"
                    name="vin"
                    placeholder="VIN"
                    type="text"
                    fullWidth
                    variant="outlined"
                    onChange={handleInputChange}
                    value={formData.vin || ""}
                  />
                  {(mode === "edit" || mode === "delete") && (
                    <Button variant="outlined" onClick={handleSearchVIN}>
                      <Search />
                    </Button>
                  )}
                </Box>
                {(mode === "add" || mode === "edit") && selectedTable !== "Dar de alta una unidad" && (
                  <>
                    <InputLabel
                      htmlFor="numero_siniestro"
                      sx={{
                        color: "#0267d3",
                        fontSize: "1.3rem",
                        fontWeight: 700,
                        textTransform: "capitalize",
                        marginBottom: "1rem",
                        marginTop: "2rem",
                      }}
                    >
                      Número de siniestro
                    </InputLabel>
                    {mode === "edit" ? (
                      <Autocomplete
                        options={data.results.filter(item => item.vin === formData.vin)}
                        getOptionLabel={(option) => option.numero_siniestro}
                        onInputChange={handleNumeroSiniestroChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="dense"
                            id="numero_siniestro"
                            name="numero_siniestro"
                            placeholder="Número de siniestro"
                            type="text"
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        margin="dense"
                        id="numero_siniestro"
                        name="numero_siniestro"
                        placeholder="Número de siniestro"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleInputChange}
                        value={formData.numero_siniestro || ""}
                      />
                    )}
                  </>
                )}
                {noResultsNumeroSiniestro && (
                  <Box sx={{ color: "red", marginBottom: "1rem" }}>
                    No hay coincidencias
                  </Box>
                )}
                {getFormFields()}
              </Box>
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontSize: "1.4rem" }} onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" sx={{ fontSize: "1.4rem" }}>
            {mode === "delete" ? "Eliminar" : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { FormDialog };