import React, { useState } from "react";
import { SinisterTable } from "../tables/SinisterTable";
import { SearchBar } from "app/components/SearchBar/SearchBar";
import { Box, Button, Typography } from "@mui/material";
import {
  Add,
  AttachMoney,
  CarCrash,
  Delete,
  Description,
  Edit,
} from "@mui/icons-material";
import { FormDialog } from "app/components";
import { getInsuranceReports } from "app/api/reportsDataApi";
import { useSelector } from "react-redux";
import { BillsTable } from "../tables/BillsTable";
import { BillPaymentsTable } from "../tables/BillPaymentsTable";
import "./Content.scss";

const FirstTabContent = () => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [pagination, setPagination] = useState({
    next: null,
    previous: null,
    totalCount: 0,
    resultsPerPage: 20, // Cambia esto si necesitas una cantidad diferente por página
  });
  const { startDay, endDay, startMonth, endMonth, startYear, endYear } =
    useSelector((state) => state.dateform);

  const [currentPage, setCurrentPage] = useState(1);

  const handleClose = () => setOpen(false);

  const handleOpenDialog = (mode) => {
    setMode(mode);
    setOpen(true);
  };

  const handleSearch = async (query, pageUrl = null) => {
    try {
      const results = await getInsuranceReports(
        query,
        startDay,
        endDay,
        startMonth,
        endMonth,
        startYear,
        endYear,
        pageUrl
      );
      setSearchResults(results.results);

      // Actualiza la paginación
      setPagination((prev) => ({
        ...prev,
        next: results.next,
        previous: results.previous,
        totalCount: results.count, // Asigna el total de resultados de la API
      }));

      setHasSearched(true);

      if (!pageUrl) {
        setCurrentPage(1);
      }
    } catch (error) {
      console.error("Error fetching search results", error);
    }
  };

  const handlePageChange = (pageUrl) => {
    handleSearch("", pageUrl);

    if (pageUrl === pagination.next) {
      setCurrentPage((prev) => prev + 1);
    } else if (pageUrl === pagination.previous) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const noResults =
    hasSearched && (!searchResults || searchResults.length === 0);

  const totalPages = Math.ceil(
    pagination.totalCount / pagination.resultsPerPage
  );

  return (
    <Box className="rc">
      <Box className="rc-search">
        <SearchBar onSearch={(query) => handleSearch(query)} />
      </Box>
      <Box className="rc-actions">
        <Button onClick={() => handleOpenDialog("add")}>
          <Add sx={{ fontSize: "2rem" }} />
        </Button>
        <Button onClick={() => handleOpenDialog("edit")}>
          <Edit sx={{ fontSize: "2rem" }} />
        </Button>
        <Button onClick={() => handleOpenDialog("delete")}>
          <Delete sx={{ fontSize: "2rem" }} />
        </Button>
      </Box>
      {noResults && (
        <Box className="rc-no-results">
          <Typography variant="h5" color="error" sx={{ textAlign: "center" }}>
            No se encontraron resultados que coincidan con el criterio de
            búsqueda o la fecha seleccionada.
          </Typography>
        </Box>
      )}
      {searchResults && searchResults.length > 0 && (
        <Box>
          <Box className="rc-data">
            <h2>
              <CarCrash />
              Movimientos de Siniestros
            </h2>
            <SinisterTable
              data={searchResults}
              onPageChange={handlePageChange}
              next={pagination.next}
              previous={pagination.previous}
              totalPages={totalPages} // Pasar totalPages
              currentPage={currentPage} // Pasar currentPage
            />
          </Box>
          <Box className="rc-data">
            <h2>
              <Description />
              Movimientos de Facturas
            </h2>
            <BillsTable
              data={searchResults}
              onPageChange={handlePageChange}
              next={pagination.next}
              previous={pagination.previous}
              totalPages={totalPages} // Pasar totalPages
              currentPage={currentPage} // Pasar currentPage
            />
          </Box>
          <Box className="rc-data">
            <h2>
              <AttachMoney />
              Pago de Facturas
            </h2>
            <BillPaymentsTable
              data={searchResults}
              onPageChange={handlePageChange}
              next={pagination.next}
              previous={pagination.previous}
              totalPages={totalPages} // Pasar totalPages
              currentPage={currentPage} // Pasar currentPage
            />
          </Box>
        </Box>
      )}
      <FormDialog
        open={open}
        handleClickOpen={handleOpenDialog}
        handleClose={handleClose}
        titles={{
          edit: "Editar registro",
          add: "Crear registro",
          delete: "Eliminar registro",
        }}
        mode={mode}
      />
    </Box>
  );
};

export { FirstTabContent };
