import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "app/routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsAuthenticated } from "./redux/slices/auth/authSlice";
import { isLogged } from "./api/authApi";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const isLoggedUser = async () => {
      const response = await isLogged();
      if (response) {
        dispatch(setIsAuthenticated(true));
      }
    };

    isLoggedUser()
  }, [dispatch]);


  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
