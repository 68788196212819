import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { ErrorAlert, PasswordItem, SuccessAlert, TextItem } from "app/components";
import { useSelector } from "react-redux";
import axiosInstance from "config/axiosConfig";
import { SuccessAlertReload } from "app/components/Alerts/SuccessAlertReload";

const SecondTabContent = () => {
  const users = useSelector((state) => state.users.users);
  const permissions = useSelector((state) => state.users.permissions);
  const [firstClicked, setFirstClicked] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [selectedUserIndex, setSelectedUserIndex] = useState("");
  const [userData, setUserData] = useState({
    slug: "",
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    permission_slug: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleUserChange = (event) => {
    const selectedIndex = event.target.value;
    setSelectedUserIndex(selectedIndex);
    const selectedUser = users[selectedIndex];
    setUserData({
      slug: selectedUser?.slug || "",
      username: selectedUser?.username || "",
      first_name: selectedUser?.first_name || "",
      last_name: selectedUser?.last_name || "",
      email: selectedUser?.email || "",
      password: "",
      permission_slug: selectedUser?.permission_slug || "",
    });
  };

  const handleSaveChanges = async () => {
    if (!passwordsMatch) {
      ErrorAlert("Las contraseñas no coinciden")
      return;
    }
    if (!userData.permission_slug) {
      ErrorAlert("Selecciona un permiso para este usuario")
      return;
    }
    const { slug, ...userDataWithoutSlug } = userData;
    const selectedPermission = permissions.find(
      (permission) => permission.slug === userData.permission_slug
    );

    userDataWithoutSlug.permission_slug = selectedPermission.slug;

    try {
      setFirstClicked(true);
      const response = await axiosInstance.put(`/api/v1/users/${slug}/`, userDataWithoutSlug);

      if (response.status === 200) {
        SuccessAlertReload("Usuario actualizado con éxito")
      } else {
        ErrorAlert("Corrobora que todos los campos hayan sido llenados correctamente")
      }
    } catch (error) {
      ErrorAlert("Corrobora que todos los campos hayan sido llenados correctamente")
    } finally{
      setFirstClicked(false);
    }
  };

  return (
    <Box className="content">
      <Box className="content-container">
        <Box className="content-box">
          <FormControl fullWidth>
            <p
              style={{
                fontSize: "1.3rem",
                margin: "1.3rem 0",
                paddingBottom: "1rem",
              }}
            >
              Selecciona el usuario
            </p>
            <Select
              value={selectedUserIndex}
              onChange={handleUserChange}
              sx={{ marginBottom: "2rem", color: "#787878" }}
            >
              {users && users.map((user, index) => (
                <MenuItem key={user.slug} value={index} sx={{ color: "#787878" }}>
                  {user.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextItem
            text="Nombre de usuario"
            label="Nombre de usuario"
            name="username"
            value={userData.username}
            onChange={handleChange}
          />
          <TextItem
            text="Nombre"
            label="Nombre"
            name="first_name"
            value={userData.first_name}
            onChange={handleChange}
          />
          <TextItem
            text="Apellido"
            label="Apellido"
            name="last_name"
            value={userData.last_name}
            onChange={handleChange}
          />
          <TextItem
            text="Correo electrónico"
            label="Correo electrónico"
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
          <PasswordItem
            text="Contraseña"
            label="Contraseña"
            name="password"
            setPassword={setUserData}
            setPasswordsMatch={setPasswordsMatch}
            value={userData.password}
            onChange={handleChange}
          />
          <FormControl fullWidth>
            <p
              style={{
                fontSize: "1.3rem",
                margin: "1.3rem 0",
                paddingBottom: "1rem",
              }}
            >
              Selecciona el tipo de usuario
            </p>
            <Select
              name="permission_slug"
              value={userData.permission_slug}
              onChange={handleChange}
            >
              {permissions &&
                permissions.map((permission) => (
                  <MenuItem key={permission.slug} value={permission.slug}>
                    {permission.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        <Box className="content-actions">
          <Button variant="contained" onClick={handleSaveChanges} disabled={firstClicked}>
            Guardar cambios
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { SecondTabContent };
