import React from 'react';
import TableChartIcon from "@mui/icons-material/TableChart";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Box, Button, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';


const PrebuiltItem = ({ title, desc, generate, download }) => {
  return (
    <Box className="rc-prebuilt-item" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "4rem 0", width: "100%" }}>
      <Typography sx={{ fontSize: "1.3rem", display: "flex", alignItems: "center", justifyContent: "flex-start", width: "20rem" }}>
        <TableChartIcon sx={{ color: "#63AEFE", fontSize: "2.5rem", marginRight: "1rem" }} />
        {title}
      </Typography>
      <Typography sx={{ fontSize: "1.3rem", display: "flex", alignItems: "center", justifyContent: "flex-start", width: "20rem" }}>
        {desc}
      </Typography>
      <Box className="rc-prebuilt-btns" sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "20rem" }}>
        <Button onClick={generate} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "1.3rem", width: "fit-content", margin: 0, padding: 0, }} >
          <Settings sx={{ marginRight: "1rem", fontSize: "2.5rem" }} />
        </Button>
        {title === "Seguros" && (
          <Button onClick={download} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", fontSize: "1.3rem", width: "fit-content", margin: 0, padding: 0, }} >
            <SimCardDownloadIcon sx={{ marginRight: "1rem", fontSize: "2.5rem" }} />
          </Button>
        )}
      </Box>
    </Box>
  )
}

export { PrebuiltItem };