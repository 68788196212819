import React, { useEffect, useState } from "react";
import { TotalCard } from "app/components/TotalCard/TotalCard";
import { AttachMoney, Event, Public, School, Group } from "@mui/icons-material";
import { Box } from "@mui/material";
import { AirportShuttle } from "@mui/icons-material";
import { getDistributorFees } from "app/services/dashboard";
import { formatMonth } from "app/utils/constants";
import { useSelector } from "react-redux";

const defaultOption = { 
  concesionario_id: "1", // ID para "Todos"
  concesionario: "Ninguna opción seleccionada",
};

const TotalCards = ({ distributor }) => {
  const [distributorFees, setDistributorFees] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState(defaultOption);
  const [totals, setTotals] = useState({
    totalCuotaAsociacion: 0,
    totalCuotaConvencion: 0,
    totalCuotaPublicidad: 0,
    totalCuotaCapacitacion: 0,
    totalCuotaAmda: 0,
    totalCuotaSeguro: 0, // Nueva cuota
    totalCuotas: 0,
  });

  const { startDay, endDay, startMonth, endMonth, startYear, endYear } =
    useSelector((state) => state.dateform);

  const startPrincipalDate = `${formatMonth(startDay)}${formatMonth(
    startMonth
  )}${startYear}`;
  const finalPrincipalDate = `${formatMonth(endDay)}${formatMonth(
    endMonth
  )}${endYear}`;

  useEffect(() => {
    fetchDistributorFees(); // Llama a la función aquí al montar el componente
  }, []); // Se ejecuta solo al montar el componente

  const fetchDistributorFees = async () => {
    try {
      const response = await getDistributorFees(startPrincipalDate, finalPrincipalDate);
      setDistributorFees(Array.isArray(response) ? response : []);
      calculateTotals(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateTotals = (fees) => {
    const newTotals = fees.reduce((acc, fee) => {
      acc.totalCuotaAsociacion += parseFloat(fee.total_cuota_asociacion || 0);
      acc.totalCuotaConvencion += parseFloat(fee.total_cuota_convencion || 0);
      acc.totalCuotaPublicidad += parseFloat(fee.total_cuota_publicidad || 0);
      acc.totalCuotaCapacitacion += parseFloat(fee.total_cuota_capacitacion || 0);
      acc.totalCuotaAmda += parseFloat(fee.total_cuota_amda || 0);
      acc.totalCuotaSeguro += parseFloat(fee.total_cuota_seguro || 0); // Sumar nueva cuota
      acc.totalCuotas += parseFloat(fee.total_cuotas || 0);
      return acc;
    }, {
      totalCuotaAsociacion: 0,
      totalCuotaConvencion: 0,
      totalCuotaPublicidad: 0,
      totalCuotaCapacitacion: 0,
      totalCuotaAmda: 0,
      totalCuotaSeguro: 0, // Inicializar nueva cuota
      totalCuotas: 0,
    });
    
    setTotals(newTotals);
  };

  useEffect(() => {
    fetchDistributorFees(); // Llama a la función aquí cuando cambien las fechas
  }, [startPrincipalDate, finalPrincipalDate]); // Escucha cambios en las fechas

  useEffect(() => {
    if (distributor) {
      setSelectedDistributor(distributor);
    } else {
      setSelectedDistributor(defaultOption);
    }

    if (Array.isArray(distributorFees) && distributor) { // Asegúrate de que distributorFees sea un array
      const selectedFees = distributorFees.filter(fee =>
        (distributor && distributor.concesionario_id !==1 && Object.keys(distributor).length !== 0) ? fee.concesionario_id === distributor.concesionario_id : true
      );
  
      calculateTotals(selectedFees);
    } else {
      calculateTotals(distributorFees);
    }
  }, [distributor, distributorFees]);

  return (
    <Box className="w-full flex flex-wrap items-center justify-center xl:justify-start gap-3">
      <TotalCard
        icon={<AttachMoney />}
        bgColor="#63AEFE"
        title="Total Cuota Asociación"
        indicator={totals.totalCuotaAsociacion}
      />
      <TotalCard
        icon={<Event />}
        bgColor="#63AEFE"
        title="Total Cuota Convención"
        indicator={totals.totalCuotaConvencion}
      />
      <TotalCard
        icon={<Public />}
        bgColor="#63AEFE"
        title="Total Cuota Publicidad"
        indicator={totals.totalCuotaPublicidad}
      />
      <TotalCard
        icon={<School />}
        bgColor="#63AEFE"
        title="Total Cuota Capacitación"
        indicator={totals.totalCuotaCapacitacion}
      />
      <TotalCard
        icon={<Group />}
        bgColor="#63AEFE"
        title="Total Cuota AMDA"
        indicator={totals.totalCuotaAmda}
      />
      <TotalCard
        icon={<AirportShuttle />}
        bgColor="#63AEFE"
        title="Total Cuota Seguro" // Nueva tarjeta para la cuota de seguro
        indicator={totals.totalCuotaSeguro}
      />
      <TotalCard
        icon={<AirportShuttle />}
        bgColor="#63AEFE"
        title="Total de Cuotas"
        indicator={totals.totalCuotas}
      />
    </Box>
  );
};

export { TotalCards };
