import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { BasicDataTable } from "app/components";
import { billPaymentsHeaders, columnMapping } from "app/utils/constants";

const BillPaymentsTable = ({ data, onPageChange, next, previous, totalPages, currentPage }) => {
  const newHeaders = billPaymentsHeaders.map(header => columnMapping[header] || header);

  const filteredData = data ? data.map((item) => {
    const filteredItem = {};
    billPaymentsHeaders.forEach((header) => {
      filteredItem[header] = item[header];
    });
    return filteredItem;
  }) : [];

  return (
    <Box className="rc-container">
      <Box className="rc-data" sx={{ padding: 0 }}>
        <BasicDataTable
          headers={newHeaders}
          data={filteredData}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 2 }}>
        <Button variant="contained" onClick={() => onPageChange(previous)} disabled={!previous}>
          Anterior
        </Button>
        <Typography variant="body1">
          Página {currentPage} de {totalPages}
        </Typography>
        <Button variant="contained" onClick={() => onPageChange(next)} disabled={!next}>
          Siguiente
        </Button>
      </Box>
    </Box>
  );
};

export { BillPaymentsTable };
