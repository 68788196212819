import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    reportType: ""
}

const reportTypeSlice = createSlice({
  name: "reportType",
  initialState,
  reducers: {
    setReportType: (state, action) => {
        state.reportType = action.payload;
    }
  }
});

export const { setReportType } = reportTypeSlice.actions;

export default reportTypeSlice.reducer;