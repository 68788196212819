import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordItem = ({ text, label, setPassword, setPasswordsMatch }) => {
  const [password, setPasswordLocal] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPasswordLocal(newPassword);
    setPassword((prevUserData) => ({
      ...prevUserData,
      password: newPassword,
    }));
    setPasswordsMatch(newPassword === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordsMatch(password === newConfirmPassword);
  };

  return (
    <form>
      <div className={`password-item ${password !== confirmPassword ? 'password-mismatch' : ''}`}>
        <p>{text}</p>
        <FormControl sx={{ m: 1, width: "25ch", margin: "1rem 0 !important"}} variant="outlined">
          <InputLabel htmlFor="filled-adornment-password">{label}</InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: "25ch", margin: "1rem 0 !important" }} variant="outlined">
          <InputLabel htmlFor="filled-adornment-confirm-password">Confirmar Contraseña</InputLabel>
          <OutlinedInput
            type={showPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {password !== confirmPassword && <span className="error-message">Las contraseñas no coinciden</span>}
      </div>
    </form>
  );
};

export { PasswordItem };
