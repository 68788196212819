import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    users: [],
    permissions: null,
}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setGetUsers: (state, action) => {
        state.users = action.payload;
    },
    setPermissions: (state, action) => {
        state.permissions = action.payload;
    },
    setCurrentUser: (state, action) => {
        state.user = action.payload;
    },
  }
});

export const { setCurrentUser, setGetUsers, setPermissions } = usersSlice.actions

export default usersSlice.reducer