import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 isPanelOpen: false,
}

const panelSettingsSlice = createSlice({
  name: "panelSettings",
  initialState,
  reducers: {
    setIsPanelOpen: (state, action) => {
        state.isPanelOpen = action.payload;
    }
  }
});

export const { setIsPanelOpen } = panelSettingsSlice.actions

export default panelSettingsSlice.reducer