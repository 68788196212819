import Swal from "sweetalert2";

const GuideAlert = (title, message) => {
    Swal.fire({
        title: title,
        text: message,
        icon: "question",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#63AEFE",
      });
}

export { GuideAlert };