import React from "react";
import { Box, Button } from "@mui/material";
import { Delete, TableView } from "@mui/icons-material";
import { formatDate } from "app/utils/constants";
import "./FileListItem.scss";

const FileListItem = React.forwardRef(
  ({ filename, created, onClick, onDelete, uploadYear, uploadMonth }, ref) => {
    const createdDate = formatDate(created);
    const formatedMonth = `${uploadMonth < 10 ? `0${uploadMonth}` : ""}`;
    return (
      <Box className="flItem">
        <Box className="flItem-desc">
          <p className="flItem-desc-item"> <TableView color="success" sx={{ fontSize: "2.5rem", marginRight: ".5rem" }} /> Excel</p>
          <p className="flItem-desc-item flItem-desc__name">{filename}</p>
          <p className="flItem-desc-item">{createdDate}</p>
          <p className="flItem-desc-item">{uploadYear}</p>
          <p className="flItem-desc-item">{formatedMonth}</p>
          <Box className="flItem-desc-item flItem-desc__delete">
            <Button onClick={onClick}>
              <Delete color="error" sx={{ fontSize: "2.5rem" }} />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);

export { FileListItem };
