import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import axiosInstance from "config/axiosConfig";
import { Avatar, ErrorAlert } from "app/components";
import { shortName } from "app/utils/constants";
import { SuccessAlertReload } from "../Alerts/SuccessAlertReload";
import "./UserCard.scss";

const UserCard = ({ slug, name, lastname, userType }) => {
  const [firstClicked, setFirstClicked] = useState(false);
  const handleDelete = async () => {
    try {
      setFirstClicked(true);
      Swal.fire({
        title: "¿Estás seguro que deseas eliminar este usuario?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#63AEFE",
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axiosInstance.delete(`/api/v1/users/${slug}/`);
          if (
            response.status === 200 ||
            response.status === 204 ||
            response.response === true
          ) {
            SuccessAlertReload("Usuario eliminado con éxito");
          } else {
            ErrorAlert("No ha sido posible eliminar el usuario, intente más tarde");
          }
        }
      });
    } catch (error) {
      ErrorAlert("No ha sido posible eliminar el usuario, intente más tarde");
    } finally {
      setFirstClicked(false);
    }
  };

  return (
    <Box
      key={slug}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "7rem",
        padding: "0 1.5rem",
        margin: "1.3rem 0",
      }}
    >
      <Box className="ucard">
        <Box className="ucard-container">
          <Box className="ucard-header">
            <Avatar name={name} lastname={lastname} />
          </Box>
          <Box className="ucard-desc">
            <h2
              style={{
                fontSize: "2rem",
                margin: "0 0 .5rem 0",
                fontWeight: 700,
              }}
            >
              {shortName(name, lastname)}
            </h2>
            <p
              style={{
                fontSize: "1.1rem",
                textTransform: "uppercase",
                fontWeight: 500,
                margin: "0 0 .5rem 0",
              }}
            >
              {userType}
            </p>
          </Box>
          <Box sx={{ marginLeft: "auto" }}>
            <Button onClick={handleDelete} disabled={firstClicked}>
              <DeleteIcon color="error" sx={{ fontSize: "2.3rem" }} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { UserCard };
