import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

const SimpleBackdrop = () => {
  const loading = useSelector((state) => state.loader.loading);

  return (
    <div>
    {loading === true ? (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : ""}
    </div>
  );
};

export { SimpleBackdrop };