import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    indicators: [],
    updatedData: [],
}

const indicatorsSlice = createSlice({
  name: "indicators",
  initialState,
  reducers: {
    setIndicators: (state, action) => {
        state.indicators = action.payload;
    },
    setUpdatedData: (state, action) => {
      state.updatedData  = action.payload;
    }
  }
});

export const { setIndicators, setUpdatedData } = indicatorsSlice.actions

export default indicatorsSlice.reducer