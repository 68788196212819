import { useEffect } from "react";
import { fetchPermissions } from "app/api/permissionsApi";
import { UsersControl } from "app/layouts/UsersControl/UsersControl";
import { setPermissions } from "app/redux/slices/data/usersSlice";
import { useDispatch } from "react-redux";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPermissionsData = async () => {
      try {
        const permissionsData = await fetchPermissions();
        dispatch(setPermissions(permissionsData));
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissionsData();
  }, [dispatch]);

  return <UsersControl />;
};

export { Users };
