import axiosInstance from "config/axiosConfig";

export const isLogged = async () => {
  try {
    const response = await axiosInstance.get("/api/v1/users/is-logged/");
    return response.data.data.isLogged;
  } catch (error) {
    console.error("Error al conectarse con el servidor:", error.message);
    return false
  }
};