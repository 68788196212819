import Swal from 'sweetalert2';

const ErrorAlert = (message) => {
    Swal.fire({
        icon: "error",
        title: "Error",
        text: message,
        customClass: {
          title: "alert-title-class",
          container: "alert-content-class",
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#63AEFE",
      });
}

export { ErrorAlert }