import React from 'react';
import { Box } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import "./TotalCard.scss";

const TotalCard = ({ icon, bgColor, title, indicator }) => {
  const props = useSpring({
    from: { number: 0 },
    to: { number: indicator },
    config: { duration: 1000 },
  });

  return (
    <Box className="tcard">
      <Box className="tcard__left" sx={{ backgroundColor: bgColor }}>
        {icon}
      </Box>
      <Box className="tcard__right">
        <h3>{title}</h3>
        <animated.p>
          {props.number.to((val) => val.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))}
        </animated.p>
      </Box>
    </Box>
  );
};

export { TotalCard };
