import Swal from 'sweetalert2';

const SuccessAlertReload = (title) => {
    Swal.fire({
        icon: "success",
        title: title,
        customClass: {
          title: "alert-title-class",
          container: "alert-content-class",
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#63AEFE",
      }).then((result) => {
        if (result.isConfirmed) {
            window.location.reload();
        }
    });
}

export { SuccessAlertReload };