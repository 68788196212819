import React, { useEffect } from "react";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { Box, Button, Typography } from "@mui/material";
import { SwipeLeftAlt, SwipeRightAlt } from "@mui/icons-material";
import { createReport } from "app/api/reportsDataApi";
import { DataTable } from "app/components";
import { setSortConfigs } from "app/redux/slices/data/sortDataSlice";
import { CustomizedSearchBar } from "app/layouts/ContabilityReports/CustomizedSearchBar/CustomizedSearchBar";
import { useDispatch, useSelector } from "react-redux";
import { setTotalDataCount } from "app/redux/slices/data/totalDataCountSlice";
import { columnMapping } from "app/utils/constants";
import { setCurrentPage, setCurrentTable, setData, setFilteredData, setHeaders, setTotalPages } from "app/redux/slices/data/tablesSlice";
import "./Content.scss";

const FirstTabContent = () => {
  const data = useSelector((state) => state.tables.data);
  const filteredData = useSelector((state) => state.tables.filteredData);
  const headers = useSelector((state) => state.tables.headers);
  const totalPages = useSelector((state) => state.tables.totalPages);
  const currentTable = useSelector((state) => state.tables.currentTable);
  const currentPage = useSelector((state) => state.tables.currentPage);
  const toggleFetchTable = useSelector((state) => state.tables.toggleFetchTable);
  const reportType = useSelector((state) => state.reportType.reportType);
  const searchQuery = useSelector((state) => state.searchQuery.searchQuery);
  const selectedColumns = useSelector((state) => state.columns.selectedColumns);
  const { startDay, endDay, startMonth, endMonth, startYear, endYear } = useSelector((state) => state.dateform);

  const dispatch = useDispatch();
  const pageSize = 50;

  useEffect(() => {
    if (toggleFetchTable) {
      if (currentTable === "constructedTable") fetchDataWithColumnsSelection(selectedColumns, currentPage, searchQuery, startDay, endDay, startMonth, endMonth, startYear, endYear);
      else fetchDataWithColumnsSelection([], currentPage, searchQuery, startDay, endDay, startMonth, endMonth, startYear, endYear)
    }
  // eslint-disable-next-line
  }, [
    toggleFetchTable,
    currentPage,
    currentTable,
    searchQuery,
  ]);

  const fetchDataWithColumnsSelection = async (selectedColumns, page, search, startDay, endDay, startMonth, endMonth, startYear, endYear) => {
    try {
      const searchText = search || "";
      const response = await createReport(selectedColumns, page, searchText, startDay, endDay, startMonth, endMonth, startYear, endYear, reportType);
      updateDataState(response);
    } catch (error) {
      console.error("Error fetching data: ", error.message);
    }
  };

  const updateDataState = (response) => {
    const { count, results } = response.data.data;
    const totalDataCount = count;
    const responseData = results;
    let responseHeaders = [];

    if (results.length > 0) {
      responseHeaders = Object.keys(results[0]);
    }

    const totalPages = totalDataCount === 0 ? 1 : Math.ceil(totalDataCount / pageSize);
    const newHeaders = responseHeaders.map(header => columnMapping[header] || header);
    dispatch(setData(responseData));
    dispatch(setHeaders(newHeaders));
    dispatch(setTotalDataCount(totalDataCount));
    dispatch(setTotalPages(totalPages));
  };

  useEffect(() => {
    if (data) {
      const filtered = searchQuery
        ? data.filter((item) =>
          Object.values(item).some((value) =>
            value !== null && value.toString().trim().toLowerCase().includes(searchQuery.trim().toLowerCase())
          )
        )
        : data;

      const validCurrentPage =
        currentPage > totalPages ? totalPages : currentPage;

      dispatch(setFilteredData(filtered));
      dispatch(setTotalPages(totalPages));
      dispatch(setCurrentPage(validCurrentPage));
    }
  // eslint-disable-next-line
  }, [data, searchQuery, currentPage]);


  const handleCleanTable = () => dispatch(setCurrentTable(null));
  const handleCleanFilter = () => dispatch(setSortConfigs({}));
  const handlePageChange = (page) => dispatch(setCurrentPage(page));

  const scrollLeft = () => {
    const container = document.querySelector(".rc-data");
    if (container) {
      container.scrollLeft -= 350;
    }
  };

  const scrollRight = () => {
    const container = document.querySelector(".rc-data");
    if (container) {
      container.scrollLeft += 350;
    }
  };

  const renderTable = () => (
    <Box className="rc-data" sx={{ padding: 0 }}>
      <DataTable
        headers={headers}
        data={filteredData}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
    </Box>
  )

  return (
    <Box className="rc-container">
      <Box className="rc-header">
        <CustomizedSearchBar
          placeholder="Buscar"
        />
      </Box>
      <Box className="rc-actions">
        <Button onClick={handleCleanTable}> <CleaningServicesIcon sx={{ fontSize: "2rem" }} /> </Button>
        <Button onClick={handleCleanFilter}> <FilterListOffIcon sx={{ fontSize: "2rem" }} /> </Button>
        <Button className="scroll-button left" onClick={scrollLeft}> <SwipeLeftAlt sx={{ fontSize: "2rem" }} /> </Button>
        <Button className="scroll-button right" onClick={scrollRight}> <SwipeRightAlt sx={{ fontSize: "2rem" }} /> </Button>
      </Box>
      {data && data.length > 0 && headers && currentTable && renderTable()}
      {data && data.length === 0 && currentTable !== null && (<Typography sx={{ fontWeight: 700, fontSize: "1.5rem", fontStyle: "oblique", textAlign: "center", width: "100%", marginTop: "7rem" }}>No hay datos para mostrar en este rango de fechas</Typography>)}
    </Box>
  );
};

export { FirstTabContent };
