import React from "react";
import { DragAndDrop } from "app/components/DragAndDrop/DragAndDrop";
import { PageTitle } from "app/components/PageTitle/PageTitle";
import { Box } from "@mui/material";
import "./FilesControl.scss";
import { UploadedFiles } from "./UploadedFiles/UploadedFiles";

const FilesControl = () => {
  return (
    <Box className="fc">
      <Box className="fc-container">
        <Box className="fc-item">
          <Box className="fc-item-header">
            <Box className="fc-item-header__text fc-item__text-one">
              <PageTitle title={"Archivos"} text={"Cargar archivos"} />
            </Box>
          </Box>
          <Box className="fc-item-body">
            <DragAndDrop />
          </Box>
        </Box>
        <Box className="fc-item">
          <Box className="fc-item-header">
            <Box className="fc-item-header__text">
              <PageTitle title={"Archivos"} text={"Archivos cargados"} />
            </Box>
          </Box>
          <Box className="fc-item-body fc-table">
              <UploadedFiles />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { FilesControl };
