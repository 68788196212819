import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { Box } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setSortConfigs } from "app/redux/slices/data/sortDataSlice";

const DataTable = ({ headers, data, totalPages, onPageChange, currentPage }) => {
  const sortConfigs = useSelector((state) => state.sortData.sortConfigs);
  const dispatch = useDispatch();

  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  const handleSort = (column) => {
    const currentSortOrder = sortConfigs[column] || "asc";
    const nextSortOrder = currentSortOrder === "asc" ? "desc" : "asc";
    dispatch(setSortConfigs({ ...sortConfigs, [column]: nextSortOrder }));
  };

  const getSortIcon = (column) => {
    if (sortConfigs[column] === "asc") {
      return <ArrowUpward />;
    } else if (sortConfigs[column] === "desc") {
      return <ArrowDownward />;
    } else {
      return null;
    }
  };

  const sortedData = data ? [...data].sort((a, b) => {
    return headers.reduce((accumulator, header) => {
      const sortOrder = sortConfigs[header];
      if (!sortOrder) return accumulator;
      if (accumulator !== 0) return accumulator;
      if (a[header] < b[header]) return sortOrder === "asc" ? -1 : 1;
      if (a[header] > b[header]) return sortOrder === "asc" ? 1 : -1;
      return 0;
    }, 0);
  }) : [];

  return (
    <Box className="data-table">
      <Table>
        <TableHead>
          <TableRow>
            {headers &&
              headers.map((header) => (
                <TableCell
                  sx={{
                    width: "100%",
                    minWidth: "100",
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                  }}
                  key={header}
                  onClick={() => handleSort(header)}
                >
                  <strong>{header}</strong>
                  {getSortIcon(header)}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData &&
            sortedData.map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((cell, index) => (
                  <TableCell
                    sx={{
                      width: "100%",
                      minWidth: "100%",
                      whiteSpace: "nowrap",
                    }}
                    key={index}
                    align="right"
                  >
                    {cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box className="pagination" sx={{ margin: "2rem 0" }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{ "& .MuiPaginationItem-root": { fontSize: "1.5rem" } }}
        />
      </Box>
    </Box>
  );
};

export { DataTable };