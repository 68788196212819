import React from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

import { PageTitle } from "app/components/PageTitle/PageTitle";
import { TabNavigation } from "app/components/TabNavigation/TabNavigation";
import { FirstTabContent } from "app/layouts/UsersControl/Content/FirstTabContent";
import { SecondTabContent } from "app/layouts/UsersControl/Content/SecondTabContent";
import { ThirdTabContent } from "app/layouts/UsersControl/Content/ThirdTabContent";
import illustration from "app/assets/images/illustration.jpg";
import "./UsersControl.scss";

const UsersControl = () => {
  return (
    <div className="uc">
      <div className="uc-container">
        <div className="uc-container-form">
          <div className="uc-header">
            <PageTitle title={"Usuarios"} text={"Control de usuarios"} />
          </div>
          <div className="uc-body">
            <TabNavigation
              tabs={[
                {
                  icon: <AddIcon sx={{ marginRight: "1rem" }} />,
                  label: "Crear usuario",
                  content: FirstTabContent,
                },
                {
                  icon: <EditIcon sx={{ marginRight: "1rem" }} />,
                  label: "Editar usuario",
                  content: SecondTabContent,
                },
                {
                  icon: <VerifiedUserIcon sx={{ marginRight: "1rem" }} />,
                  label: "Listado",
                  content: ThirdTabContent,
                },
              ]}
            />
          </div>
        </div>
        <div className="uc-advice">
          <div className="uc-advice-container">
            <div className="uc-advice-text">
              <h3>Consejo:</h3>
              <p>
                Manten tu contraseña segura y no la compartas con nadie más.
              </p>
            </div>
            <figure className="uc-advice-header">
              <img src={illustration} alt="Safe tip illustration" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export { UsersControl };
