import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    files: []
}

const uploadFilesSlice = createSlice({
  name: "uploadFiles",
  initialState,
  reducers: {
    setFiles: (state, action) => {
        state.files = action.payload;
    }
  }
});

export const { setFiles } = uploadFilesSlice.actions;

export default uploadFilesSlice.reducer;