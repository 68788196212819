import axiosInstance from 'config/axiosConfig';

const fetchPermissions = async () => {
  try {
    const response = await axiosInstance.get('/api/v1/permissions/');
    const permissionsData = response.data.data.results.map(permission => ({
      name: permission.name,
      slug: permission.slug
    }));
    return permissionsData;
  } catch (error) {
    console.error('Error fetching permissions:', error);
    return [];
  }
};

export { fetchPermissions };