import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { PageTitle, TabNavigation } from "app/components";
import {
  CalendarMonth,
  GridView,
  Hardware,
  TableRows,
} from "@mui/icons-material";
import { FirstTabContent } from "./content/FirstTabContent";
import { SecondTabContent } from "./content/SecondTabContent";
import { ThirdTabContent } from "./content/ThirdTabContent";
import { Dateform } from "app/components/shared/Forms/Dateform";
import "./ContabilityReports.scss";

const ContabilityReports = () => {
  const [openDateform, setOpenDateform] = useState(false);

  const handleOpenDateform = () => {
    setOpenDateform(true);
  };

  const handleCloseDateform = () => {
    setOpenDateform(false);
  };

  return (
    <Box className="rc">
      <Box className="rc-container">
        <Box className="rc-container-header">
          <PageTitle title="Reportes" text="Contabilidad" />
          <Button sx={{ fontSize: "1.3rem" }} onClick={handleOpenDateform}>
            <CalendarMonth sx={{ fontSize: "2.5rem" }} /> &nbsp; Selecciona un
            rango de fechas para tu reporte
          </Button>
        </Box>
        <Box className="rc-tabs">
          <TabNavigation
            tabs={[
              {
                icon: <TableRows sx={{ marginRight: "1rem" }} />,
                label: "Reporte generado",
                content: FirstTabContent,
              },
              {
                icon: <GridView sx={{ marginRight: "1rem" }} />,
                label: "Reportes prefabricados",
                content: SecondTabContent,
              },
              {
                icon: <Hardware sx={{ marginRight: "1rem" }} />,
                label: "Reportes personalizados",
                content: ThirdTabContent,
              },
            ]}
          />
        </Box>
      </Box>
      <Dateform open={openDateform} handleClose={handleCloseDateform} />
    </Box>
  );
};

export { ContabilityReports };
