import React, { useEffect } from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTable, setToggleFetchTable } from "app/redux/slices/data/tablesSlice";
import { setSelectedColumns, setColumnNames } from "app/redux/slices/data/columnsSlice";
import { getColumnNames } from "app/api/reportColumnNames";
import { randomString } from "app/utils/constants";
import { setSearchQuery } from "app/redux/slices/data/searchQuerySlice";
import { setReportType } from "app/redux/slices/data/reportTypeSlice";
import { SuccessAlert } from "app/components";
import "./Content.scss";

const ThirdTabContent = () => {
  const columnNames = useSelector((state) => state.columns.columnNames || []);
  const selectedColumns = useSelector((state) => state.columns.selectedColumns);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchColumnNames();
    //eslint-disable-next-line
  }, [dispatch]);

  const fetchColumnNames = async () => {
    const results = await getColumnNames();
    dispatch(setColumnNames(results));
  };

  const toggleColumn = (columnName) => {
    const chooseColumn = (prevSelectedColumns) => {
      if (prevSelectedColumns.includes(columnName)) {
        return prevSelectedColumns.filter((col) => col !== columnName);
      } else {
        return [...prevSelectedColumns, columnName];
      }
    };
    dispatch(setSelectedColumns(chooseColumn(selectedColumns)));
  };

  const handleCleanColumns = () => {
    dispatch(setSelectedColumns([]));
  };

  const handleTableChange = (tableName) => {
    const randString = randomString(5);
    dispatch(setSearchQuery(""));
    dispatch(setReportType("base_data"));
    dispatch(setToggleFetchTable(randString));
    dispatch(setCurrentTable(tableName));
    SuccessAlert("Reporte generado con éxito");
  };

  return (
    <Box className="rc-container">
      <Box className="rc-label" sx={{ width: "100%", marginTop: "3rem" }}>
        <span
          style={{
            color: "#63AEFE",
            fontSize: "1.4rem",
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          Crea un reporte personalizado
        </span>
      </Box>
      <Box className="rc-buildReport" sx={{ margin: "3rem 0 5rem 0" }}>
        <Box className="rc-buildReport-item" sx={{ margin: "3rem 0" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              textTransform: "uppercase",
              padding: "1rem 1.7rem",
            }}
            gutterBottom
          >
            Selecciona las columnas de tu reporte
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              overflowY: "scroll",
              height: "15rem",
              maxHeight: "15rem",
            }}
          >
            {columnNames &&
              columnNames
                .slice()
                .sort()
                .map((column) => (
                  <Button key={column} onClick={() => toggleColumn(column)}>
                    <Chip
                      label={column}
                      color={
                        selectedColumns.includes(column)
                          ? "success"
                          : "secondary"
                      }
                      variant="contained"
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 700,
                        m: 0.5,
                        p: "1 .5",
                      }}
                    />
                  </Button>
                ))}
          </Box>
        </Box>
        <Box className="rc-buildReport-item" sx={{ margin: "3rem 0" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: 700,
              textTransform: "uppercase",
              padding: "1rem 1.7rem",
            }}
            gutterBottom
          >
            Columnas seleccionadas
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mb: 2,
              overflowY: "scroll",
              height: "15rem",
              maxHeight: "15rem",
            }}
          >
            {selectedColumns &&
              selectedColumns.map((column) => (
                <Chip
                  key={column}
                  label={column}
                  onDelete={() => toggleColumn(column)}
                  color="success"
                  variant="contained"
                  sx={{ fontSize: "1rem", fontWeight: 700, m: 0.5, p: "1 .5" }}
                />
              ))}
          </Box>
        </Box>
        <Box className="rc-option">
          <Button sx={{ margin: "1.5rem 1rem" }} onClick={handleCleanColumns}>
            Limpiar selección
          </Button>
          <Button
            sx={{ margin: "1.5rem 1rem" }}
            onClick={() => handleTableChange("constructedTable")}
          >
            Generar reporte
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { ThirdTabContent };
