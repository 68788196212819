import axiosInstance from "config/axiosConfig";

export const getIndicators = async () => {
    try {
        const response = await axiosInstance.get(`/api/v1/indicators/`);
        return response.data.data.results;
    } catch (error) {
        console.error(error);
    }
}

export const updateIndicator = async (slug, name, updatedValue) => {
    try {
      const response = await axiosInstance.put(`/api/v1/indicators/${slug}/`, {  name: name,  value: updatedValue });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };