import React from "react";

import BoltIcon from '@mui/icons-material/Bolt';
import { Box } from "@mui/material";


const WaterMark = () => {
  return (
    <Box sx={{width: '100%', padding: '10px 20px 30px 20px'}}>
      <p style={{ width: '100%', textAlign: 'center', color: '#333777', fontSize: 12}}>
        <BoltIcon /> Powered by <a href="https://tigemsha.com" style={{textDecoration: 'none'}}>TIGEMSHA</a>
      </p>
    </Box>
  );
};

export { WaterMark };
