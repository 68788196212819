import React, { useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import {
  ErrorAlert,
  PasswordItem,
  SelectItem,
  SuccessAlert,
  TextItem,
} from "app/components";
import axiosInstance from "config/axiosConfig";
import "./Content.scss";
import { SuccessAlertReload } from "app/components/Alerts/SuccessAlertReload";

const FirstTabContent = () => {
  const permissions = useSelector((state) => state.users.permissions);
  const [firstClicked, setFirstClicked] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [userData, setUserData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    permission_slug: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    if (!passwordsMatch) {
      alert(
        "Las contraseñas no coinciden. Por favor, corrígelas antes de guardar."
      );
      return;
    }

    const formData = new FormData();
    formData.append("username", userData.username);
    formData.append("first_name", userData.first_name);
    formData.append("last_name", userData.last_name);
    formData.append("email", userData.email);
    formData.append("password", userData.password);
    formData.append("permission_slug", userData.permission_slug);

    try {
      setFirstClicked(true);
      const response = await axiosInstance.post("/api/v1/users/", formData);

      if (response.status === 200) {
        SuccessAlertReload("Usuario creado con éxito");
      } else {
        ErrorAlert("Corrobora que todos los campos hayan sido llenados correctamente");
      }
    } catch (error) {
      ErrorAlert("Corrobora que todos los campos hayan sido llenados correctamente");
    } finally{
      setFirstClicked(false);
    }
  };

  return (
    <div className="content">
      <div className="content-container">
        <div className="content-box">
          <TextItem
            text="¿Cuál es tu nombre?"
            label="Nombre"
            name="first_name"
            value={userData.first_name}
            onChange={handleChange}
          />
          <TextItem
            text="¿Cuál es tu apellido?"
            label="Apellido"
            name="last_name"
            value={userData.last_name}
            onChange={handleChange}
          />
          <TextItem
            text="Elige un nombre de usuario"
            label="Usuario"
            name="username"
            value={userData.username}
            onChange={handleChange}
          />
          <TextItem
            text="Escribe tu correo electrónico"
            label="Correo electrónico"
            name="email"
            value={userData.email}
            onChange={handleChange}
          />
          <PasswordItem
            text="¿Qué contraseña te gustaría usar?"
            label="Contraseña"
            name="password"
            setPassword={setUserData}
            setPasswordsMatch={setPasswordsMatch}
            value={userData.password}
            onChange={handleChange}
          />
          <SelectItem
            text="Selecciona el tipo de usuario"
            label="Permisos"
            items={permissions}
            name="permission_slug"
            value={userData.permission_slug || ""}
            onChange={handleChange}
          />
        </div>
        <div className="content-actions">
          <Button variant="contained" onClick={handleSaveChanges} disabled={firstClicked}>
            Crear usuario
          </Button>
        </div>
      </div>
    </div>
  );
};

export { FirstTabContent };
