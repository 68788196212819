import axios from 'axios';

const getCookie = (name) => {
  const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
  return cookieValue ? cookieValue.pop() : '';
};

const headers = {
  'X-CSRFToken': getCookie('csrftoken'),
  'Authorization': `token ${getCookie('sessionid')}`,
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 10000,
  headers: headers,
  withCredentials: true,
});

instance.interceptors.request.use(
  function (config) {
    const csrfToken = getCookie('csrftoken');
    const sessionId = getCookie('sessionid');


    config.headers['X-CSRFToken'] = csrfToken;
    config.headers['Authorization'] = `token ${sessionId}`;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.getWithBody = async (url, body) => {
  try {
    const response = await instance.get(url, { params: body });
    return response;
  } catch (error) {
    throw error;
  }
};


export default instance;
