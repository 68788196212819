import Swal from 'sweetalert2';

const SuccessAlert = (title) => {
    Swal.fire({
        icon: "success",
        title: title,
        customClass: {
          title: "alert-title-class",
          container: "alert-content-class",
        },
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#63AEFE",
      })
}

export { SuccessAlert };