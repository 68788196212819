import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isMenuOpen: false,
}

const navbarContainerSlice = createSlice({
  name: "navbarContainer",
  initialState,
  reducers: {
    setIsMenuOpen: (state, action) => {
        state.isMenuOpen = action.payload;
    }
  }
});

export const { setIsMenuOpen } = navbarContainerSlice.actions

export default navbarContainerSlice.reducer