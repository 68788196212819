import { Box } from "@mui/material";
import { avatarLetters } from "app/utils/constants";

const Avatar = ({ name, lastname }) => {
  const firstNameLetters = avatarLetters(name, lastname);

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "center",
        width: "5rem",
        height: "5rem",
        backgroundColor: "#64AEFE",
        borderRadius: "100%",
      }}
    >
      <span
        style={{
          color: "white",
          fontSize: "2rem",
          fontWeight: 700,
          letterSpacing: ".2rem",
        }}
      >
        {firstNameLetters}
      </span>
    </Box>
  );
};

export { Avatar };
