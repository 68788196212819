import { Route, Routes, Navigate } from "react-router-dom";
import LogInForm from "app/layouts/LogInForm/LogInForm";
import { Upload } from "app/pages/Upload";
import { Navbar } from "app/components/shared/Navbar/Navbar";
import { Users } from "app/pages/Users";
import { Contability } from "app/pages/Reports";
import { Indicators } from "app/pages/Indicators";
import { Dashboard } from "app/pages/Dashboard";
import { useSelector } from "react-redux";
import { Insurance } from "app/pages/Insurance";
import { AppLoader } from "app/layouts/AppLoader/AppLoader";

const AppRoutes = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const currentPath = localStorage.getItem('currentPath') || '/dashboard';

    const handleBeforeUnload = () => {
      localStorage.setItem('currentPath', window.location.pathname);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

  return (
    <Routes>
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/" /> : <LogInForm />}
      />
      <Route
        path="/"
        element={isAuthenticated ? <><AppLoader /></> : <Navigate to="/login" />}
      />
      <Route
        path="/dashboard"
        element={isAuthenticated ? <><Navbar /><Dashboard /></> : <Navigate to="/login" />}
      />
      <Route
        path="/reportes-contabilidad"
        element={isAuthenticated ? <><Navbar /><Contability /></> : <Navigate to="/login" />}
      />
      <Route
        path="/reportes-seguros"
        element={isAuthenticated ? <><Navbar /><Insurance /></> : <Navigate to="/login" />}
      />
      <Route
        path="/usuarios"
        element={isAuthenticated ? <><Navbar /><Users /></> : <Navigate to="/login" />}
      />
      <Route
        path="/indicadores"
        element={isAuthenticated ? <><Navbar /><Indicators /></> : <Navigate to="/login" />}
      />
      <Route
        path="/archivos"
        element={isAuthenticated ? <><Navbar/><Upload /></> : <Navigate to="/login" />}
      />
      <Route path="*" element={isAuthenticated ? <Navigate to={currentPath} /> : <Navigate to="/login" />} />

    </Routes>
  );
};

export { AppRoutes };