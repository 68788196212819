import { createSlice } from '@reduxjs/toolkit'

const currentYear = new Date().getFullYear();

const initialState = {
    startDay: 1,
    endDay: 31,
    startMonth: 1,
    endMonth: 12,
    startYear: currentYear,
    endYear: currentYear,
}

const dateformSlice = createSlice({
  name: "dateform",
  initialState,
  reducers: {
    setDateform: (state, action) => {
      state.startDay = action.payload.startDay !== undefined ? action.payload.startDay : state.startDay;
      state.endDay = action.payload.endDay !== undefined ? action.payload.endDay : state.endDay;
      state.startMonth = action.payload.startMonth !== undefined ? action.payload.startMonth : state.startMonth;
      state.endMonth = action.payload.endMonth !== undefined ? action.payload.endMonth : state.endMonth;
      state.startYear = action.payload.startYear !== undefined ? action.payload.startYear : state.startYear;
      state.endYear = action.payload.endYear !== undefined ? action.payload.endYear : state.endYear;
  }
  }
});

export const { setDateform } = dateformSlice.actions

export default dateformSlice.reducer