import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    appLoading: false,
}

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoading: (state, action) => {
        state.loading = action.payload;
    },
    setAppLoading: (state, action) => {
      state.appLoading = action.payload;
  }
  }
});

export const { setLoading, setAppLoading } = loaderSlice.actions;

export default loaderSlice.reducer;