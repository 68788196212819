import * as React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { CloseOutlined } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

const MuiAlert = ({ variant, severity, text, text2,  action }) => {
  return (
    <Stack sx={{ width: "100%", margin: 0, padding: 0, position: "relative" }} spacing={2}>
      <Alert variant={variant} severity={severity} sx={{ fontSize: 14, display: "flex" }}>
        <Box  sx={{  display: "flex", flexDirection: "column", justifyContent: "space-between" ,alignItems: "start", overflow: "hidden" }}>
          <Typography sx={{ fontSize: "1.3rem" }}>{text}</Typography>
          <Typography sx={{ fontSize: "1.3rem" }}>{text2}</Typography>
          <Button onClick={action} sx={{ margin: "0 -12px 4px 0", paddingRight: 0, position: "absolute", top: 0, right: 0}}>
          <CloseOutlined color="info"/>
          </Button>
        </Box>
      </Alert>
    </Stack>
  );
};

export { MuiAlert };
