import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  filteredData: null,
  headers: [],
  toggleFetchTable: "",
  currentTable: null,
  currentPage: 1,
  totalPages: null,
  insuranceData: null,
  allInsuranceData: null,
};

const tablesSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setHeaders: (state, action) => {
      state.headers = action.payload;
    },
    setToggleFetchTable: (state, action) => {
      state.toggleFetchTable = action.payload;
    },
    setCurrentTable: (state, action) => {
      state.currentTable = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setInsuranceData: (state, action) => {
      state.insuranceData = action.payload;
    },
    setFilteredData: (state, action) => {
      state.filteredData = action.payload;
    },
    setAllInsuranceData: (state, action) => {
      state.allInsuranceData = action.payload;
    }
  },
});

export const {
  setData,
  setHeaders,
  setToggleFetchTable,
  setCurrentTable,
  setCurrentPage,
  setTotalPages,
  setInsuranceData,
  setFilteredData,
  setAllInsuranceData
} = tablesSlice.actions;

export default tablesSlice.reducer;
