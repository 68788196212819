import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "app/api/usersApi";
import { setGetUsers } from "app/redux/slices/data/usersSlice";
import { UserCard } from "app/components/UserCard/UserCard";
import { Box } from "@mui/material";
import "./Content.scss";

const ThirdTabContent = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);

  useEffect(() => {
    async function fetchData(){
      const data = await getUsers();
      dispatch(setGetUsers(data));
    }
    fetchData();
  }, [dispatch]);

  return (
    <Box className="ttc">
      <Box className="ttc-container">
        {users && users.map((user) => (
          <UserCard
            key={user.slug}
            slug={user.slug}
            name={user.first_name}
            lastname={user.last_name}
            userType={user.permission.name}
          />
        ))}
      </Box>
    </Box>
  );
};

export { ThirdTabContent };
