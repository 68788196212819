import React from "react";
import { FilesControl } from "app/layouts/FilesControl/FilesControl";

const Upload = () => {
  return (
    <FilesControl />
  );
};

export { Upload };
