import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export function BasicAutocomplete({ acOptions, onChange, label, defaultOption }) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={acOptions}
      getOptionLabel={(option) => option.concesionario}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option) => (
        <li {...props} className="text-2xl p-5 cursor-pointer hover:bg-[#63aefe4c]">
          {option.concesionario}
        </li>
      )}
      className="w-[300px]"
      onChange={(event, value) => {
        if (onChange) {
          onChange(value);
        }
      }}
      defaultValue={defaultOption}
    />
  );
}
