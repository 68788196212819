import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { PageTitle, TabNavigation } from "app/components";
import { CalendarMonth, TableRows } from "@mui/icons-material";
import { FirstTabContent } from "./content/FirstTabContent";
import { Dateform } from "app/components/shared/Forms/Dateform";
import { getInsuranceReports } from "app/api/reportsDataApi";
import { setInsuranceData, setAllInsuranceData } from "app/redux/slices/data/tablesSlice";
import { useDispatch, useSelector } from "react-redux";

const InsuranceReports = () => {
  const [openDateform, setOpenDateform] = useState(false);
  const data = useSelector((state) => state.tables.insuranceData);
  const dispatch = useDispatch();

  const defaultStartDay = 1;
  const defaultEndDay = 31;
  const defaultStartMonth = 1;
  const defaultEndMonth = 12;
  const defaultStartYear = 2000;
  const defaultEndYear = new Date().getFullYear();

  const { startDay, endDay, startMonth, endMonth, startYear, endYear } = useSelector((state) => state.dateform);

  const handleOpenDateform = () => {
    setOpenDateform(true);
  };

  const handleCloseDateform = () => {
    setOpenDateform(false);
    fetchInsuranceData(startDay, endDay, startMonth, endMonth, startYear, endYear);
  };

  useEffect(() => {
    fetchInsuranceData(defaultStartDay, defaultEndDay, defaultStartMonth, defaultEndMonth, defaultStartYear, defaultEndYear, true);
  }, []);

  const fetchInsuranceData = async (startDay, endDay, startMonth, endMonth, startYear, endYear, initialFetch = false) => {
    try {
      const response = await getInsuranceReports(null, startDay, endDay, startMonth, endMonth, startYear, endYear);
      dispatch(setInsuranceData(response));
      if (initialFetch) {
        dispatch(setAllInsuranceData(response));
      }
    } catch (error) {
      console.error('>>>>>>>>>>> error', error);
    }
  };

  return (
    <Box className="rc">
      <Box className="rc-container">
        <Box className="rc-container-header">
          <PageTitle title="Reportes" text="Seguros" />
          <Button sx={{ fontSize: "1.3rem" }} onClick={handleOpenDateform}>
            <CalendarMonth sx={{ fontSize: "2.5rem" }} /> &nbsp; Selecciona un
            rango de fechas para tu reporte
          </Button>
        </Box>
        <Box className="rc-tabs">
          <TabNavigation
            tabs={[
              {
                icon: <TableRows sx={{ marginRight: "1rem" }} />,
                label: "General",
                content: FirstTabContent,
              },
            ]}
          />
        </Box>
      </Box>
      <Dateform open={openDateform} handleClose={handleCloseDateform} data={data} />
    </Box>
  );
};

export { InsuranceReports };
