import { ErrorAlert } from "app/components/Alerts/ErrorAlert";
import axiosInstance from "config/axiosConfig";

const getUploadUrl = (reportType, year, month) => {
  let url = `/api/v1/reports/upload-file/?`;
  switch(reportType) {
    case "insurance":
      url += `type=insurance`;
      break;
    case "update_base_data":
      url += `type=update_base_data&month=${month}&year=${year}`;
      break;
    default:
      url += `type=base_data&month=${month}&year=${year}`;
  }
  return url;
};

export const uploadFile = async (formData, reportType, year, month) => {
  try {
    const url = getUploadUrl(reportType, year, month);
    const response = await axiosInstance.post(url, formData, { headers: { "Content-Type": "multipart/form-data" } });
    return response;
  } catch (error) {
    if (error.response) {
      const { status } = error.response;
      let errorMessage = "Error al cargar el archivo. Inténtalo de nuevo más tarde.";

      switch (status) {
        case 400:
          if (error.response && error.response.data && error.response.data.messages ){
            const messages = error.response.data.messages;
            const errorDetails = [];
            const duplicatedAccidents = messages[0]?.duplicated ? `${messages[0].duplicated} ${messages[0].detail}` : null;
            if (duplicatedAccidents) {
              errorDetails.push(duplicatedAccidents);
            } else {
              messages.forEach((message) => {
                const fieldsAndErrors = message.match(/'([^']+)'[^[]+\[ErrorDetail\(string='([^']+)'/);
                if (fieldsAndErrors && fieldsAndErrors.length === 3) {
                  const field = fieldsAndErrors[1];
                  const errorDetail = fieldsAndErrors[2];
                  const errorMessage = `${field}: ${errorDetail}`;
                  errorDetails.push(errorMessage);
                }
              });
            }
            if (errorDetails === null || errorDetails === undefined || errorDetails.length === 0) {
              errorDetails.push(messages[0])
            }
            errorMessage = errorDetails;
          } else{
            errorMessage = "¡Oops! Parece que hubo un problema con el archivo. Por favor, revisa que sea el correcto y tenga el formato adecuado."
          }
          break;
        case 401:
          errorMessage = "No autorizado. Por favor, inicia sesión e intenta cargar el archivo nuevamente.";
          break;
        case 404:
          errorMessage = "Archivo no encontrado. Por favor, verifica la ruta del archivo y vuelve a intentarlo.";
          break;
        case 500:
          errorMessage = "Reporte con formato incorrecto, valida nuevamente.";
          break;
        default:
          break;
      }
      ErrorAlert(errorMessage);
    } else {
      ErrorAlert("Error al cargar el archivo. Inténtalo de nuevo más tarde.");
    }
  }
};
