import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, Plugin } from 'chart.js';

// Registra los elementos necesarios de Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, Title);

// Plugin para mostrar el total en el centro del gráfico
const centerTextPlugin = {
    id: 'centerText',
    afterDatasetsDraw: (chart) => {
        const { ctx, chartArea } = chart;
        if (!chartArea) return;

        const totalUnits = chart.data.datasets[0].data.reduce((acc, val) => acc + val, 0);
        const fontSize = (chartArea.bottom - chartArea.top) / 3;

        ctx.save();
        ctx.font = `${fontSize}px Arial`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#fff'; // Color del texto para destacar
        ctx.fillText(totalUnits, chartArea.left + (chartArea.right - chartArea.left) / 2, chartArea.top + (chartArea.bottom - chartArea.top) / 2);
        ctx.restore();
    },
};

export const UnitsPerDistributorChart = ({ distributor, data = [] }) => { // Establecer data por defecto como un array vacío
    // Filtrar los datos según el distribuidor recibido por props
    const filteredData = (distributor && distributor.concesionario_id !==1 && Object.keys(distributor).length !== 0) 
        ? data.filter(item => item.concesionario_id === distributor.concesionario_id)
        : data;

    // Preparar los datos para el gráfico
    const chartData = {
        labels: filteredData.map(item => item.concesionario || 'Sin nombre'), // Manejo de nombres de concesionarios
        datasets: [
            {
                label: 'Total de Unidades',
                data: filteredData.map(item => item.count || 0), // Asegurarse de que el conteo esté definido
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                    '#FF9F40',
                    '#FF6384',
                    '#4BC0C0',
                    '#36A2EB',
                    '#FFCE56',
                ].map(color => color + 'CC'), // Añadir transparencia a los colores
                hoverOffset: 4,
            },
        ],
    };

    // Opciones para la gráfica
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Desactivar las etiquetas en la parte superior
            },
            title: {
                display: true,
                text: 'Unidades por Distribuidor',
            },
            centerText: { // Agregar el plugin para el texto central
                display: true,
            },
        },
    };

    return <Pie data={chartData} options={options} plugins={[centerTextPlugin]} />;
};
