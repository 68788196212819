import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    fileDetails: [],
}

const uploadLogsSlice = createSlice({
  name: "uploadLogs",
  initialState,
  reducers: {
    setFileDetails: (state, action) => {
        state.fileDetails = action.payload;
    }
  }
});

export const { setFileDetails } = uploadLogsSlice.actions

export default uploadLogsSlice.reducer