import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDateform } from "app/redux/slices/data/dateformSlice";
import { randomString } from "app/utils/constants";
import { setToggleFetchTable } from "app/redux/slices/data/tablesSlice";
import { ErrorAlert, SuccessAlert } from "app/components";
import { useLocation } from "react-router-dom";

const Dateform = ({ open, handleClose }) => {
  const dateform = useSelector((state) => state.dateform);
  const currentTable = useSelector((state) => state.tables.currentTable);
  const randString = randomString(5);
  const dispatch = useDispatch();
  const location = useLocation();
  const [localDateform, setLocalDateform] = useState({ ...dateform });

  const months = React.useMemo(
    () => [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    []
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (location.pathname === "/reportes-contabilidad" && currentTable === null) {
      handleClose();
      ErrorAlert("No puedes editar fechas hasta que hayas generado un reporte.");
      return;
    }
    setLocalDateform({ ...localDateform, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleApplyDate();
      handleClose();
    }
  };

  const handleApplyDate = () => {
    const { startDay, endDay, startMonth, endMonth, startYear, endYear } = localDateform;
    if (location.pathname === "/reportes-contabilidad" && currentTable === null) {
      handleClose();
      ErrorAlert("Para aplicar fechas, primero necesitas generar un reporte.");
      return;
    }
    if (
      (startYear && ((startYear.length !== 4 && parseInt(startYear) < 1990) || isNaN(parseInt(startYear)))) ||
      (endYear && ((endYear.length !== 4 && parseInt(endYear) < 1990) || isNaN(parseInt(endYear))))
    ) {
      handleClose();
      ErrorAlert("Ingresa un año válido");
      return;
    }
    if (
      (startDay && ((startDay.length > 2 || startDay.length < 1 || parseInt(startDay) > 31 || parseInt(startDay) < 1) || isNaN(parseInt(startDay)))) ||
      (endDay && ((endDay.length > 2 || startDay.length < 1 || parseInt(endDay) > 31 || parseInt(endDay) < 1) || isNaN(parseInt(endDay))))
    ) {
      handleClose();
      ErrorAlert("Ingresa un día válido");
      return;
    }

    dispatch(setDateform(localDateform));
    dispatch(setToggleFetchTable(randString));
    handleClose();
    SuccessAlert("Cambios aplicados con éxito!");
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
        }}
      >
        <DialogTitle sx={{ fontSize: "1.5rem", paddingTop: "2rem" }}>Selecciona un rango de fechas</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", gap: "3rem", margin: "2rem auto 3rem auto" }}>
            <TextField
              autoFocus
              required
              margin="dense"
              name="startDay"
              label="Día inicio"
              value={localDateform.startDay}
              onChange={handleChange}
              fullWidth
              variant="standard"
              onKeyDown={handleKeyDown}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mes</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localDateform.startMonth}
                name="startMonth"
                label="Mes inicio"
                onChange={handleChange}
              >
                {months.map((monthName, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {monthName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              required
              margin="dense"
              name="startYear"
              label="Año inicio"
              value={localDateform.startYear}
              onChange={handleChange}
              fullWidth
              variant="standard"
              onKeyDown={handleKeyDown}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "3rem" }}>
            <TextField
              autoFocus
              required
              margin="dense"
              name="endDay"
              label="Día Fin"
              value={localDateform.endDay}
              onChange={handleChange}
              fullWidth
              variant="standard"
              onKeyDown={handleKeyDown}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mes fin</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={localDateform.endMonth}
                name="endMonth"
                label="Mes"
                onChange={handleChange}
              >
                {months.map((monthName, index) => (
                  <MenuItem key={index} value={index + 1}>
                    {monthName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              autoFocus
              required
              margin="dense"
              name="endYear"
              label="Año fin"
              value={localDateform.endYear}
              onChange={handleChange}
              fullWidth
              variant="standard"
              onKeyDown={handleKeyDown}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ fontSize: "1.2rem " }} onClick={handleClose}>
            Cerrar
          </Button>
          <Button sx={{ fontSize: "1.2rem " }} onClick={handleApplyDate}>
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export { Dateform };
