import axiosInstance from "config/axiosConfig";
import { dashboardUrls } from "./urls";

const genericGetData = async (endpoint, startDate, endDate) => {
    try {
        const params = new URLSearchParams();
        
        if (startDate) {
            params.append('start_principal_date', startDate);
        }
        if (endDate) {
            params.append('final_principal_date', endDate);
        }

        const url = `${endpoint}?${params.toString()}`;
        const response = await axiosInstance.get(url);
        return response.data.data.results;
    } catch (error) {
        console.error(error);
    }
}

export const getUnitsPerDist = (startDate, endDate) => 
    genericGetData(dashboardUrls[0].unitsPerDist, startDate, endDate);
    
export const getDistributorFees = (startDate, endDate) => 
    genericGetData(dashboardUrls[0].distributorFees, startDate, endDate);
    
export const getRemainderPerDistributor = (startDate, endDate) => 
    genericGetData(dashboardUrls[0].remainderPerDistributor, startDate, endDate);
