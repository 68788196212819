import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const SelectItem = ({ text, label, items, name, value, onChange }) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onChange({
      target: {
        name,
        value: selectedValue
      }
    });
  };

  if (!items) {
    return null;
  }

  return (
    <div className="select-item">
      <p>{text}</p>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          value={value}
          onChange={handleChange}
          autoWidth
          label={label}
        >
          {items.map((item, index) => (
            <MenuItem key={index} value={item.slug}>
              {item.name || item.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export { SelectItem };
