import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedColumns: [],
    columnNames: [],
}

const columnsSlice = createSlice({
  name: "columns",
  initialState,
  reducers: {
    setSelectedColumns: (state, action) => {
        state.selectedColumns = action.payload;
    },
    setColumnNames: (state, action) => {
        state.columnNames = action.payload;
    },
  }
});

export const { setSelectedColumns, setColumnNames } = columnsSlice.actions;

export default columnsSlice.reducer